import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Col, Image, Row, Typography } from 'antd';
import { useInView } from 'react-intersection-observer';
import { isMobile } from '../../../utils/utils';
import { SignupButton } from '../../../components/buttons/signup-button';
import { gutter, IN_VIEW, IN_VIEW_MOBILE } from '../../Home';


// SHARED VARIABLES //
const { Title, Text } = Typography
const IS_MOBILE = isMobile()


const Portfolio = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refObserver, inView] = useInView({
        triggerOnce: true,

        // Percentage of the HTML element visibility to trigger the animation
        threshold: IS_MOBILE ? IN_VIEW_MOBILE : IN_VIEW,
    })

    const springRefDescription = useSpringRef()
    const springDescription = useSpring({
        ref: springRefDescription,
        config: { duration: 400 },
        from: { opacity: inView ? 0 : 1, },
        to: {
            opacity: inView ? 1 : 0, // Set opacity based on visibility
            transform: inView ? 'translateX(0)' : 'translateX(-100px)'
        },
    })

    const springRefImage = useSpringRef()
    const springImage = useSpring({
        ref: springRefImage,
        config: { duration: 1000 },
        from: { opacity: inView ? 0 : 1, },
        to: {
            opacity: inView ? 1 : 0
        },
    })

    useChain([springRefDescription, springRefImage], [0, 1], IS_MOBILE ? 1000 : 400)


    return (
        <div className="page" ref={refObserver}>
            <div className="landing-content">
                <Row gutter={gutter}>
                    <Col xs={24} md={8}>
                        <animated.div className="text-container" style={{ ...springDescription, }}>
                            <Title level={1} className='page-title'>Intelligent Portfolio</Title>
                            <Text className='page-description'>
                                Discover the <b>intelligent portfolio</b> designed to analyze all your trades and <b>optimize</b> your performance.
                                Gain actionable <b>AI-driven insights</b> on your holdings to make smarter,
                                data-backed decisions and elevate your trading strategy.
                            </Text>
                            {!IS_MOBILE &&
                                <div>
                                    <SignupButton className="landing-signup" type='primary' label='Create Your Portfolio Free' />
                                </div>
                            }
                        </animated.div>
                    </Col>
                    <Col xs={24} md={16}>
                        <animated.div className='img-background' style={{ ...springImage, }}>
                            <Image src='/landing_portfolio.png' alt='portfolio' preview={false} style={{ padding: '0.1em' }} />
                        </animated.div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Portfolio;