import { Image, Modal, Space } from 'antd';
import { isMobile } from '../../utils/utils';


const IS_MOBILE = isMobile()


const ModalBasic = ({ open, setOpen, icon, title, children }) => {

    const handleCancel = () => {
        setOpen(false)
    }

    return (
        <Modal open={open} onCancel={handleCancel} footer={null} width={IS_MOBILE ? '100%' : '60%'}
            title={
                <Space align='center'>
                    <Image width={35} src={icon} />
                    <span className='modal-title'>{title}</span>
                </Space>}>
            {children}
        </Modal>
    )
}

export default ModalBasic;