import { Col, Divider, Image, Layout, List, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import BlogPostNavigationBottom from '../../components/BlogPostNavigationBottom';
import BlogTitle1 from '../../components/BlogTitle1';
import MyBreadcrumb from '../../components/Breadcrumb';
import { SignupButton } from '../../components/buttons/signup-button';
import { IconEMA, IconMA, IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2 } from '../../components/Icons';
import { TagAIGood, TagAIHigh, TagClassResilient, TagTrendBear, TagTrendBull } from '../../components/Tags';
import { COLOR_PRIMARY_STRONGER } from '../../JTIConst';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import { BLOG_CRUMB } from '../Blog';

const { Paragraph, Text } = Typography;
const { Item } = List;


const TradeChecklist = ({ title, imgFile, updatedDate }) => {

    // Style
    const numberFontStyle = { fontSize: '3em', color: COLOR_PRIMARY_STRONGER, fontWeight: '500' }
    const rules = [
        {
            title: <>1.&nbsp; RESILIENT ASSET</>,
            description:
                <blockquote className='blog-paragraph'>
                    Opt for assets <b>tagged</b> with &nbsp;<TagClassResilient /> and, if possible, those that provide dividends.
                    This strategy allows for transitioning from trading to investment when facing short-term downturns.
                </blockquote>
        },
        {
            title: <>2.&nbsp; BUY SIGNAL</>,
            description:
                <blockquote className='blog-paragraph'>
                    Buy at the closest price to the <b>Buy Signal</b> &nbsp;<IconSignalBuyL1 /> &nbsp;<IconSignalBuyL2 />.
                    Avoid rushing; if the right time has passed for your current target asset, look for a better opportunity - there are plenty out there!
                </blockquote>
        },
        {
            title: <>3.&nbsp; AI EFFICIENCY</>,
            description:
                <blockquote className='blog-paragraph'>
                    Choose assets <b>tagged</b> with a &nbsp;<TagAIHigh /> or <TagAIGood /> <b>AI Model Efficiency</b>.
                    If you're not an experienced trader, it's advisable to focus on this category of assets.
                </blockquote>
        },
        {
            title: <>4.&nbsp; TREND</>,
            description:
                <blockquote className='blog-paragraph'>
                    Use the <Link to="/macroview">Indexes View</Link> to understand the current <b>Market Trend</b> <TagTrendBull /> or <TagTrendBear />,
                    as it is crucial for determining your strategy and how long you should maintain your position.
                </blockquote>
        },
        {
            title: <>5.&nbsp; SELL SIGNAL</>,
            description:
                <blockquote className='blog-paragraph'>
                    Know your <b>exit strategy</b> before entering the market.
                    Here are all the exit points in chronological order: <b>Take Profit: <IconEMA /> L1 &nbsp;<IconMA /> L2, Sell Signal: &nbsp;<IconSignalSellL1 />L1 &nbsp;&nbsp;<IconSignalSellL2 />L2</b>.<br />
                    In times of market uncertainty, we recommend taking <b>partial or all profits</b> at the <b>Take Profit Levels</b> <IconEMA /> &nbsp;<IconMA />,
                    then exiting the trade entirely at the <b>Sell Signal</b> &nbsp;<IconSignalSellL1 /> &nbsp;<IconSignalSellL2 /> for the last owned shares.
                </blockquote>
        },
    ]

    return (
        <Layout className="site-layout-content-fixed site-layout-content-blog">
            <MetaTags
                title={title}
                description="Discover key fundamental factors to evaluate before making a trade and boost your chances of success in the financial markets." />

            <MyBreadcrumb items={BLOG_CRUMB.concat([
                {
                    title: title,
                }
            ])} />

            <BlogTitle1>
                • {title}
                <div className='blog-title-meta'>{updatedDate}</div>
            </BlogTitle1>

            <Paragraph className='blog-paragraph'>
                <Image width="100%" src={imgFile} alt="Trading checklist" />
            </Paragraph>

            <Paragraph className='blog-paragraph'>
                Before buying any asset, please check a few fundamental points to maximize your chances of success:

                <List style={{ margin: '15px 0 0 0' }}
                    dataSource={rules}
                    renderItem={(item, index) => (
                        <Item style={{ margin: '1.4em 0 0 0' }}>
                            <Row align='middle'>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} xxl={{ span: 9 }}><span style={numberFontStyle}>{item.title}</span></Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 15 }} lg={{ span: 15 }} xl={{ span: 15 }} xxl={{ span: 15 }}>{item.description}</Col>
                            </Row>
                        </Item>
                    )} />
            </Paragraph>

            <br /><br />
            <Divider className='blog-divider'>MANTRA</Divider>

            <div id="mindset" style={{ marginBottom: 0 }}>
                <Paragraph className='blog-paragraph'>
                    <Row gutter={[0, 70]}>
                        <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                            <Text className='quote'>
                                "Trading is a matter of patience"<br />
                            </Text>
                            _____<br />
                            <Text italic>
                                People often seek rapid, substantial gains or make impulsive purchases due to the fear of missing out (FOMO).
                            </Text>
                        </Col>
                        <Col xs={{ offset: 6, span: 18 }} sm={{ offset: 6, span: 18 }} md={{ offset: 12, span: 12 }} lg={{ offset: 12, span: 12 }} xl={{ offset: 12, span: 12 }} xxl={{ offset: 12, span: 12 }}>
                            <Text className='quote'>
                                "Take your profit, don’t chase the moon"<br />
                            </Text>
                            _____<br />
                            <Text italic>
                                It's better to secure moderate profits multiple times than waiting for extra returns.
                            </Text>
                        </Col>
                        <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                            <Text className='quote'>
                                "Trading is a game of fear and greed"<br />
                            </Text>
                            _____<br />
                            <Text italic>
                                Fear often emerges when prices drop, and greed when prices rise, leading to buying and selling opposite of what you should do.
                            </Text>
                        </Col>
                    </Row>
                </Paragraph>
            </div>

            <SignupButton className="blog-cta" type='primary' label='Start Investing In Stocks Today' />

            <BlogPostNavigationBottom />
        </Layout>
    )
}

export default TradeChecklist;