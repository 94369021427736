import { Typography } from 'antd';
import React from 'react';
import PageTitle1 from '../../components/PageTitle1';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import { FAQ_CRUMB } from "../FAQ"
import MyBreadcrumb from '../../components/Breadcrumb';
import { Link } from 'react-router-dom';


const { Paragraph } = Typography;

const title = "What is Just Trade It?"


const WhatIsJustTradeIt = ({ onlyContent = false }) => {

    const content = <>
        <Paragraph className='blog-paragraph'>
            Just Trade It is an AI-powered trading platform designed to help traders and investors make smarter, data-driven decisions in the stock market.
            It combines advanced AI models, interactive charts, and fundamental trading tools to optimize your trading experience.
        </Paragraph>
        <Paragraph className='blog-paragraph'>
            To learn more, please visit: <Link to="/about-jti" >About JTI</Link>
        </Paragraph>
    </>

    if (onlyContent)
        return content

    return (<>
        <MetaTags
            title={title}
            description="Just Trade It is an AI-powered trading platform designed to help traders and investors make smarter, data-driven decisions in the stock market. It combines advanced AI models, interactive charts, and fundamental trading tools to optimize your trading experience."
            keywords="AI trading platform, stock market tools, data-driven trading, Just Trade It" />

        <MyBreadcrumb items={FAQ_CRUMB.concat([
            {
                title: title,
            }
        ])} />

        <PageTitle1>• {title}</PageTitle1>

        {content}
    </>)
}

export default WhatIsJustTradeIt;