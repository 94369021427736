import { Layout, Card, Col, Row } from 'antd';
import PageTitle1 from '../components/PageTitle1';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';
import MetaTags from '../MetaTags';
import { GRID_GUTTER } from '../JTIConst';


export const BLOG_CRUMB = [
    {
        title: <Link to="/">Home</Link>,
    },
    {
        title: <Link to="/blog">Blog</Link>,
    },
]

export const blogPosts = [
    {
        title: "The Swing Resilient Strategy",
        description: "Trading consists of two simple actions: buy, then sell. The challenge lies in executing these actions...",
        createdDate: "4/3/2024",
        updatedDate: "4/3/2024",
        path: "swing-resilient-strategy",
        imgFile: "swing-resilient-strategy.jpg"
    },
    {
        title: "Just Trade It: Trading Checklist",
        description: "Before buying any asset, please check a few fundamental points to maximize your chances of...",
        createdDate: "16/9/2024",
        updatedDate: "16/9/2024",
        path: "trading-checklist",
        imgFile: "trading-checklist.jpg"
    },
    {
        title: "Common Stocks vs. Preferred Stocks: Which One Should You Choose?",
        description: "When it comes to stock market investing, understanding the different types of stocks...",
        createdDate: "20/12/2024",
        updatedDate: "20/12/2024",
        path: "common-stocks-vs-preferred-stocks",
        imgFile: "common-stocks-vs-preferred-stocks.jpg"
    },
    {
        title: "The Basics of Dividend Investing: How to Create a Passive Income Stream",
        description: "Dividend investing has long been regarded as one of the most effective ways to build wealth...",
        createdDate: "20/12/2024",
        updatedDate: "20/12/2024",
        path: "the-basics-of-dividend-investing",
        imgFile: "the-basics-of-dividend-investing.jpg"
    },
    {
        title: "Top 10 Growth Stocks to Watch in 2025",
        description: "Growth stocks are typically companies that are expected to outperform the broader market...",
        createdDate: "2/1/2025",
        updatedDate: "2/1/2025",
        path: "top-10-growth-stocks-2025",
        imgFile: "top-10-growth-stocks-2025.jpg"
    },
    {
        title: "Stock Market Investing: A Guide to Building Wealth with Confidence",
        description: "Investing in the stock market is a proven way to grow your wealth and achieve financial goals...",
        createdDate: "2/1/2025",
        updatedDate: "2/1/2025",
        path: "stock-market-investing",
        imgFile: "stock-market-investing.jpg"
    },
    {
        title: "Understanding Ratios, Terms, and Metrics in Fundamental Analysis for Stock Market Investing",
        description: "Fundamental analysis is the method of evaluating a company’s intrinsic value by examining its...",
        createdDate: "6/1/2025",
        updatedDate: "6/1/2025",
        path: "understanding-ratios-terms-and-metrics-in-fundamental-analysis-for-stock-market-investing",
        imgFile: "understanding-ratios-terms-and-metrics-in-fundamental-analysis-for-stock-market-investing.jpg"
    },
    {
        title: "Understanding Price-to-Earnings (P/E) Ratio: How to Evaluate Stock Valuation",
        description: "The Price-to-Earnings (P/E) ratio is one of the most widely used and important metrics in stock market...",
        createdDate: "16/1/2025",
        updatedDate: "16/1/2025",
        path: "price-to-earnings-ratio",
        imgFile: "price-to-earnings-ratio.jpg"
    },
    {
        title: "Understanding Index Funds: A Beginner’s Guide to Passive Investing",
        description: "Index funds are a popular investment option for beginners and experienced investors alike, offering an easy way...",
        createdDate: "21/1/2025",
        updatedDate: "21/1/2025",
        path: "index-funds",
        imgFile: "index-funds.jpg"
    },
    {
        title: "How to Build a Diversified Stock Portfolio: Tips and Strategies",
        description: "Building a diversified stock portfolio is one of the most effective ways to reduce risk and increase...",
        createdDate: "21/1/2025",
        updatedDate: "21/1/2025",
        path: "diversified-stock-portfolio",
        imgFile: "diversified-stock-portfolio.jpg"
    },
    {
        title: "Top 5 Books Every Stock Market Investor Should Read",
        description: "Stock market investing can seem overwhelming at first, but with the right knowledge, tools, and mindset...",
        createdDate: "6/2/2025",
        updatedDate: "6/2/2025",
        path: "top-investing-books",
        imgFile: "top-investing-books.jpg"
    },
    {
        title: "The Role of Technical Analysis in Stock Market Investing",
        description: "Technical analysis is a method used by investors and traders to evaluate and predict future price...",
        createdDate: "8/2/2025",
        updatedDate: "8/2/2025",
        path: "technical-analysis-stock-market",
        imgFile: "technical-analysis-stock-market.jpg"
    },
    {
        title: "Price-to-Book (P/B) Ratio Explained: How to Identify Undervalued Stocks",
        description: "The Price-to-Book (P/B) ratio is another important financial metric used in fundamental analysis to evaluate....",
        createdDate: "13/2/2025",
        updatedDate: "13/2/2025",
        path: "price-to-book-ratio",
        imgFile: "price-to-book-ratio.jpg"
    },
]

const Blog = () => {
    return (
        <Layout className="site-layout-content">
            <MetaTags
                title="Just Trade It: Blog"
                description="Explore insightful articles and tutorials on trading to enhance your knowledge and skills. Dive into AI-powered trading strategies, 
                market trends, and expert insights to make smarter investment decisions with Just Trade It." />

            <PageTitle1> • Blog</PageTitle1>

            <Row gutter={GRID_GUTTER}>
                {blogPosts.map((post, index) => (
                    <Col xs={{ span: 12 }} md={{ span: 6 }} key={index}>
                        <Link to={`/blog/${post.path}`}>
                            <Card className='card' bordered hoverable
                                cover={<img alt={post.title} src={`/blog/${post.imgFile}`} />}>
                                <Meta title={post.title}
                                    description={<>
                                        {post.description}
                                        <div style={{ float: 'right', fontStyle: 'italic', marginTop: '1em' }}>
                                            {post.updatedDate}
                                        </div>
                                    </>} />
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Layout>
    );
};

export default Blog;