import { Space } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title.js";
import { TagAIAverage, TagAIGood, TagAIHigh, TagAILow, TagClassResilient, TagClassYoung, TagFinStrong, TagFinWeak } from '../Tags.jsx';
import { InfoBalanceSheet, InfoHoldingsChart, InfoIncomeStatements } from "../TempLang.jsx";
import CandlestickChartTT from "../tooltips/CandlestickChartTT.jsx";


export const tourStepDemoWelcome = (tkrShortName, tkrSymbol) => {
    return {
        title: <Title level={2} align='center'>Welcome To JTI Demo</Title>,
        description: (<>
            <Paragraph>
                Here is the <b>Chart Analysis View</b> for <b>{tkrShortName} ({tkrSymbol})</b>.
            </Paragraph>
            <Paragraph>
                This is the main view of the <b>Just Trade It Platform</b>, offering a clear perspective on a stock powered by our <b>AI model</b>.
                The candlesticks are displayed in <b>real-time</b>, and <b>signals are updated every day after the market closes</b>.
            </Paragraph>
        </>)
    }
}

export const tourStepSymbolSearchBox = {
    title: '• Symbol Search Box',
    description: 'Explore additional analyses by entering a company name or stock symbol in the search box.'
}

export const tourStepWatchlist = {
    title: '• Your Watchlist',
    description: 'Here is your watchlist!'
}

export const tourStepAddToWatchlist = {
    title: '• Save To Watchlist',
    description: 'Save the current analysis in your watchlist.'
}

export const tourStepPortfolio = {
    title: '• Manage Your Portfolio',
    description: 'Open the portfolio panel.'
}

export const tourStepTags = {
    title: '• Tags',
    description: (<Paragraph>
        This section presents the tags associated with the current asset. Showing insightful pros and cons to be aware of before investing on the asset.
        <Space style={{ marginTop: '0.8em' }} wrap>
            <TagAIHigh />
            <TagAIGood />
            <TagAIAverage />
            <TagAILow />
            <TagClassResilient />
            <TagClassYoung />
            <TagFinStrong />
            <TagFinWeak />
        </Space>
    </Paragraph>)
}

export const tourStepAssetDetails = {
    title: '• Company Details',
    description: (<Paragraph>
        This section presents general informations regarding the current asset.
    </Paragraph>)
}

export const tourStepSignalsAndModelEfficiency = {
    title: '• AI Signals And Model Efficiency',
    description: (<>This segment presents the performance data of the AI model. <Text strong>Buy and Sell signals will be highlighted with color upon occurrence.</Text></>)
}

export const tourStepCandlestickChart = {
    title: '• AI Candlestick Chart',
    description: (<CandlestickChartTT />)
}

export const tourStepFinancialCharts = {
    title: '• Financial Charts',
    description: (<>
        <div style={{ marginBottom: '8px' }}>
            <Text strong>Income Statements : </Text><InfoIncomeStatements short={true} />
        </div>
        <div>
            <Text strong>Balance Sheet : </Text><InfoBalanceSheet short={true} />
        </div>
    </>)
}

export const tourStepHoldingsChart = {
    title: '• Holdings Charts',
    description: <InfoHoldingsChart />
}

export const tourStepMoreInfos = {
    title: '• More Informations',
    description: 'Access to more informations regarding the analysis, request for support or replay the tour.'
}

export const tourStepEnding = {
    title: '',
    description: (<Title level={2}>Good Trades! :)</Title>)
}