import { gray } from '@ant-design/colors'
import { MenuOutlined, ProfileOutlined, PlusSquareOutlined, FullscreenOutlined } from '@ant-design/icons';
import { IconInfoCandlestickChart } from '../components/Icons';
import { Layout, Typography, notification, theme, Skeleton, Tour, Drawer, Button, Tooltip, Space, Card, Modal } from 'antd';
import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from '../components/UserContext';
import TickerLeftMenu from '../components/TickerLeftMenu.jsx';
import TickerInfo from '../components/TickerInfo.jsx';
import CandlestickChart from '../components/CandlestickChart.jsx';
import FinancialChart from '../components/FinancialChart.jsx';
import SankeyChart from '../components/SankeyChart.jsx';
import TradingBook from '../components/TradingBook.jsx';
import { formatPriceChg, getGainLossColor, getLogoUrl, isMobile, isTablet, pourcentage } from '../utils/utils';
import Typewriter from 'typewriter-effect';
import Loading from '../components/Loading';
import {
    tourStepWatchlist, tourStepAddToWatchlist, tourStepPortfolio, tourStepAssetDetails, tourStepSignalsAndModelEfficiency,
    tourStepCandlestickChart, tourStepFinancialCharts, tourStepHoldingsChart, tourStepMoreInfos, tourStepEnding,
    tourStepTags
} from '../components/tours/steps.jsx';
import { ASSET_TYPE_STOCK, TOOLTIP_ENTER_DELAY, TT_COLOR } from '../JTIConst'

// Services
import { addTickerToWatchlist } from "../services/user.service";
import { isAllowedToViewTkr } from "../services/authorization.service";
import { getTickerInfos, getTickerQuote } from "../services/ticker.service";

// Tooltips
import { FullScreen, MaxItemsWatchlist, MaxSymbolsViewed, TradingBookDescription } from '../components/TempLang';
import { NotifUpgradePlan } from '../components/Notifications.jsx';
import { useSpring, animated } from 'react-spring';
import JTIAnchor from '../components/JTIAnchor.jsx';
import PageTitle1 from '../components/PageTitle1.jsx';
import MetaTags from '../MetaTags.js';
import TickerTags from '../components/TickerTags.jsx';


// PARAMETERS //

const IS_MOBILE = isMobile()
const IS_TABLET = isTablet()
const REAL_TIME_INTERVAL = 6000     // Each 6sec
const REAL_TIME_COUNT_OFF = 20      // Real time interval stop when counter reach x (no new quotes x times)


// SHARED VARIABLES //

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const anchorMenu = [
    {
        key: 'info',
        href: '#info',
    },
    {
        key: 'ai-chart',
        href: '#ai-chart',
    },
    {
        key: 'fundamentals',
        href: '#fundamentals',
    },
    {
        key: 'holdings',
        href: '#holdings',
    },
]


const TickerAnalysis = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [addToWatchButtColor, setAddToWatchButtColor] = useState(null);
    const [tkrInfos, setTkrInfos] = useState(null);
    const [priceChg, setPriceChg] = useState(null);
    const [candleInterval, setCandleInterval] = useState('daily');
    const [tickerInfoIsLoaded, setTickerInfoIsLoaded] = useState(false);
    const [tickerLeftMenuIsLoaded, setTickerLeftMenuIsLoaded] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {
        token: { colorPrimary },
    } = theme.useToken();

    const { paramTicker } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const { getAccessTokenSilently } = useAuth0();
    const { userContext, updateUserContext, tourOpenChartAnalysis, setTourOpenChartAnalysis } = useContext(UserContext);
    const [api, contextHolder] = notification.useNotification();
    const [currTicker, setCurrTicker] = useState(null);
    const [leftMenuItems, setLeftMenuItems] = useState([]);
    const [allowedToViewTkr, setAllowedToViewTkr] = useState(true);
    const [candlesReloadKey, setCandlesReloadKey] = useState(0);

    // Price change animation
    const [springs, springApi] = useSpring(() => ({
        config: { duration: 800 },
        from: { opacity: 0.4 },
        to: {
            opacity: 1,
        },
    }))
    const animateChanges = () => {
        springApi.start({
            from: { opacity: 0.4 },
            to: {
                opacity: 1,
            },
        })
    }

    // Modal //

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // DRAWER //

    const [openDrawer, setOpenDrawer] = useState(searchParams.get('portfolioopen') ? true : false);

    const toggleDrawer = () => {
        if (openDrawer)
            setOpenDrawer(false)
        else
            setOpenDrawer(true)
    }

    const closeDrawer = () => {
        setOpenDrawer(false)

        // Reload candles display new indicators
        setCandlesReloadKey(prev => prev + 1)
    }


    // TOUR //

    const tourRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const endTour = () => {
        setTourOpenChartAnalysis(false)
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    const steps = [
        {
            ...tourStepWatchlist,
            target: () => tourRefs[0].current,
            placement: 'right'
        },
        {
            ...tourStepAddToWatchlist,
            target: () => tourRefs[1].current,
        },
        {
            ...tourStepPortfolio,
            target: () => tourRefs[8].current,
        },
        {
            ...tourStepTags,
            target: () => tourRefs[9].current,
        },
        {
            ...tourStepAssetDetails,
            target: () => tourRefs[2].current,
        },
        {
            ...tourStepSignalsAndModelEfficiency,
            target: () => tourRefs[3].current,
        },
        {
            ...tourStepCandlestickChart,
            target: () => tourRefs[4].current,
            placement: 'left',
            style: { width: '342px' }
        },
        {
            ...tourStepFinancialCharts,
            target: () => tourRefs[5].current,
            placement: 'left',
            style: { width: '342px' }
        },
        {
            ...tourStepHoldingsChart,
            target: () => tourRefs[6].current,
            placement: 'left',
            style: { width: '342px' }
        },
        {
            ...tourStepMoreInfos,
            target: () => tourRefs[7].current,
        },
        {
            ...tourStepEnding,
            target: null,
            placement: 'top'
        },
    ]

    const stepsMobile = [
        {
            ...tourStepAddToWatchlist,
            target: () => tourRefs[1].current,
        },
        {
            ...tourStepPortfolio,
            target: () => tourRefs[8].current,
        },
        {
            ...tourStepTags,
            target: () => tourRefs[9].current,
        },
        {
            ...tourStepAssetDetails,
            target: () => tourRefs[2].current,
            style: { top: '30em' }
        },
        {
            ...tourStepSignalsAndModelEfficiency,
            target: () => tourRefs[3].current,
        },
        {
            ...tourStepCandlestickChart,
            target: () => tourRefs[4].current,
            style: { top: '92vh' }
        },
        {
            ...tourStepFinancialCharts,
            target: () => tourRefs[5].current,
        },
        {
            ...tourStepHoldingsChart,
            target: () => tourRefs[6].current,
        },
        {
            ...tourStepMoreInfos,
            target: () => tourRefs[7].current,
        },
        {
            ...tourStepEnding,
            target: null,
        },
    ]

    // CANDLESTICK CHART //

    const tabListIntervals = [
        {
            key: 'daily',
            label: '1d',
        },
        {
            key: 'hourly',
            label: '12h',
            disabled: true
        },
    ]

    const CandleList = {
        daily: <CandlestickChart interval="1d" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} />,
        hourly: <CandlestickChart interval="1h" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} />,
    }

    const candleListModal = {
        daily: <CandlestickChart interval="1d" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} height='85vh' modal={true} />,
        hourly: <CandlestickChart interval="1h" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} height='85vh' modal={true} />,
    }

    // PAGE CONTEXT //

    const addToWatchlist = async () => {

        // If item is not already present in the user watchlist
        if (userContext != null && !userContext.watchlist.some(tkrUserParam => tkrUserParam.ticker === currTicker)) {

            const accessToken = await getAccessTokenSilently();
            const resp = await addTickerToWatchlist(accessToken, userContext.id, currTicker)

            // MARK [AUTH] - Max items in watchlist
            if (resp.data == null) {
                api.info(NotifUpgradePlan(MaxItemsWatchlist))
            } else {
                setLeftMenuItems([...leftMenuItems, {
                    label: currTicker.toUpperCase(),
                    key: currTicker,
                    icon: <MenuOutlined />,
                }])

                userContext.watchlist.push({ ticker: currTicker, alert: true })   // Push it cause refresh too fast VS db is getting updated
                setAddToWatchButtColor(colorPrimary)
            }
        }
    }

    // Manage new symbol selected
    const processSetCurrTicker = async (tkr) => {

        window.history.replaceState(null, '', '/tickeranalysis/' + tkr);  // modifies the current history entry
        setCurrTicker(tkr)

        if (tkr != null && tkr != '') {

            // MARK [AUTH] - X symbols viewed per month
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await isAllowedToViewTkr(accessToken, tkr)
            setAllowedToViewTkr(data.isAllowedToViewTkr)

            // Show notif if not allowed
            if (data.isAllowedToViewTkr === false)
                api.info(NotifUpgradePlan(MaxSymbolsViewed))
        }
    }

    // When tab change
    const onTabChange = async (key, event) => {
        setCandleInterval(key)
    }

    // MARK : Manage real time price update
    let countOff = 1
    let intervalId = null
    const updatePriceChg = async (tkr) => {
        const accessToken = await getAccessTokenSilently()
        const quotesResp = await getTickerQuote(accessToken, tkr)
        const lastQuote = quotesResp.data

        // If quote response is different than current, update last price and change
        if (lastQuote?.close && lastQuote?.prevClose) {
            const chg = pourcentage(lastQuote.close, lastQuote.prevClose)
            setPriceChg(prev => {
                if (prev != chg) {
                    countOff = 0
                    animateChanges()
                    return chg
                } else {
                    // No changes
                    return prev
                }
            })
        }

        // Stop real time if counter is reach > clear interval
        if (countOff >= REAL_TIME_COUNT_OFF)
            clearInterval(intervalId)
        else
            countOff++
    }


    // EFFECTS //

    useEffect(() => {

        if (userContext != null) {

            // Coming from URL with param
            if (paramTicker)
                processSetCurrTicker(paramTicker)  // TODO : Security vulnerability > filter url parameter part of ticker list

            // 1st item selected
            else if (userContext.watchlist.length > 0)
                processSetCurrTicker(userContext.watchlist[0].ticker)
        }

    }, [userContext])

    useEffect(() => {
        setIsLoading(true)

        if (currTicker != null && currTicker != '') {
            const fetchData = async () => {
                const accessToken = await getAccessTokenSilently();

                const { data, error } = await getTickerInfos(accessToken, currTicker)
                setTkrInfos(data)

                const quotesResp = await getTickerQuote(accessToken, currTicker)
                const lastQuote = quotesResp.data

                setPriceChg(pourcentage(lastQuote.close, lastQuote.prevClose))
            }
            fetchData()

            if (userContext.watchlist.some(tkrUserParam => tkrUserParam.ticker === currTicker)) {
                setAddToWatchButtColor(gray[1])
            } else {
                setAddToWatchButtColor('black')
            }

            setIsLoading(false)

            if (intervalId == null) {
                intervalId = setInterval(updatePriceChg, REAL_TIME_INTERVAL, currTicker)

                // Clear interval on component unmount
                return () => clearInterval(intervalId)
            }
        }
    }, [currTicker])

    // Manage first tour on desktop
    useEffect(() => {
        if (tickerInfoIsLoaded && tickerLeftMenuIsLoaded && userContext.tourChartAnalysis) {
            setTourOpenChartAnalysis(true)

            // Tour done > set flag to false
            userContext.tourChartAnalysis = false
            updateUserContext(userContext)
        }
    }, [tickerLeftMenuIsLoaded, tickerInfoIsLoaded])

    // Manage first tour on mobile
    useEffect(() => {
        if (isMobile() && tickerInfoIsLoaded && userContext.tourChartAnalysis) {
            setTourOpenChartAnalysis(true)

            // Tour done > set flag to false
            userContext.tourChartAnalysis = false
            updateUserContext(userContext)
        }
    }, [tickerInfoIsLoaded])


    // RETURN //

    // Loading not ready
    if (userContext == null || (userContext.watchlist.length > 0 && (currTicker === null || tkrInfos == null))) {
        return <Loading />
    }

    return (<>
        {contextHolder}

        {!IS_TABLET &&
            <JTIAnchor items={tkrInfos?.assetType === ASSET_TYPE_STOCK ? anchorMenu : anchorMenu.slice(0, 2)} />
        }

        <Layout className="site-layout-content">
            <MetaTags title="Just Trade It: AI Charts" />

            <Drawer width={990} zIndex={200} placement="right" closable={true} onClose={closeDrawer} open={openDrawer} title={(<Title level={3} style={{ margin: '0' }}><ProfileOutlined /> Portfolio</Title>)}>
                <Paragraph>
                    <TradingBookDescription />
                </Paragraph>
                <TradingBook viewMode={false} />
            </Drawer>

            {/* Left menu */}
            {!IS_MOBILE &&
                <TickerLeftMenu
                    setTickerLeftMenuIsLoaded={setTickerLeftMenuIsLoaded}
                    tourRef={tourRefs[0]}
                    items={leftMenuItems}
                    setItems={setLeftMenuItems}
                    currItem={currTicker}
                    setCurrItem={processSetCurrTicker}
                    setCandlesReloadKey={setCandlesReloadKey} />
            }

            <Content>
                {(() => {
                    // Case watchlist is empty
                    if (userContext.watchlist.length == 0 && (currTicker === null || tkrInfos == null))
                        return (
                            <Paragraph style={{ marginTop: '1em', }}>
                                <Typewriter options={{
                                    strings: 'Start by searching for a company name.',
                                    delay: 20,
                                    autoStart: true,
                                    loop: false,
                                }} />
                            </Paragraph>)

                    // Happy path
                    else
                        return (<>
                            <PageTitle1 id='info'>
                                <Space align='baseline' size={0} style={{ float: 'right', }}>
                                    <Tooltip placement='top' color={TT_COLOR} title="Open portfolio" mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                                        <Button ref={tourRefs[8]} type="text" onClick={toggleDrawer} icon={<ProfileOutlined className='button-icon' />} />
                                    </Tooltip>
                                    <Tooltip placement='top' color={TT_COLOR} title="Add to watchlist" mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                                        <Button ref={tourRefs[1]} type="text" icon={<PlusSquareOutlined style={{ fontSize: '1.3em', color: addToWatchButtColor }} />} onClick={addToWatchlist} />
                                    </Tooltip>
                                </Space>
                                <img className='page-title1-logo' width={35} src={getLogoUrl(currTicker)} /> {tkrInfos.shortName?.replace(/\s+\(The\)$/i, '')} • <animated.span style={{ ...springs, fontSize: '0.7em', color: getGainLossColor(priceChg) }}>{formatPriceChg(priceChg)}</animated.span>
                            </PageTitle1>

                            {
                                allowedToViewTkr === false ?

                                    // Not allowed to view ticker
                                    <Skeleton paragraph={{ rows: 4 }} />
                                    :
                                    <>
                                        <TickerTags tkrInfos={tkrInfos} tourRef={tourRefs[9]} />

                                        <TickerInfo tkrInfos={tkrInfos} interval={candleInterval} isLoading={isLoading} setTickerInfoIsLoaded={setTickerInfoIsLoaded} tourRefs={tourRefs} ticker={currTicker} addToWatchList={addToWatchlist} />

                                        <Card ref={tourRefs[4]} className='card-chart-candle card grid-bottom'
                                            tabBarExtraContent={
                                                <Space size={IS_MOBILE ? 'small' : 'middle'}>
                                                    <Tooltip placement='top' color={TT_COLOR} title={FullScreen} mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                                                        <Button type="text" size='small' style={{ color: 'grey' }} icon={<FullscreenOutlined />} onClick={showModal} />
                                                    </Tooltip>
                                                    <IconInfoCandlestickChart />
                                                </Space>}
                                            tabList={tabListIntervals}
                                            activeTabKey={candleInterval}
                                            onTabChange={onTabChange}
                                            tabProps={{
                                                size: 'middle',
                                            }}>
                                            {CandleList[candleInterval]}
                                        </Card>
                                        {
                                            tkrInfos.assetType === ASSET_TYPE_STOCK && (<>
                                                <div ref={tourRefs[5]} className='grid-bottom'>
                                                    <FinancialChart tkrInfos={tkrInfos} />
                                                </div>
                                                <div ref={tourRefs[6]}>
                                                    <SankeyChart tkrInfos={tkrInfos} />
                                                </div>
                                            </>)
                                        }
                                    </>
                            }
                        </>)
                })()}
            </Content>
        </Layout>

        {/* Float button tour focus */}
        <div ref={tourRefs[7]} style={{ width: '60px', height: '60px', position: 'fixed', bottom: isMobile() ? '8px' : '38px', right: isMobile() ? '15px' : '25px' }} />

        <Modal wrapClassName='modal-chart-candle' title={tkrInfos?.longName} open={isModalVisible} onCancel={handleCancel} footer={null} width='95%' centered>
            {candleListModal[candleInterval]}
        </Modal>

        {!isMobile() ? (
            // TODO : Fix behavior: 'smooth'
            <Tour open={tourOpenChartAnalysis} onClose={endTour} steps={steps} scrollIntoViewOptions={{ behavior: 'instant', block: 'center', inline: 'center' }} />
        ) : (
            <Tour open={tourOpenChartAnalysis} onClose={endTour} steps={stepsMobile} scrollIntoViewOptions={{ behavior: 'instant', block: 'center', inline: 'center' }} arrow={false} />
        )}
    </>)
}

export default TickerAnalysis;