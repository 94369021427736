import { animated, useChain, useInView, useSpring, useSpringRef } from '@react-spring/web';
import { List, Row } from 'antd';
import { Link } from 'react-router-dom';
import { COLOR_PRIMARY } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/faq.module.css';
import { isMobile } from '../../../utils/utils';
import { IN_VIEW, IN_VIEW_MOBILE } from '../../Home';


// SHARED VARIABLES //
const IS_MOBILE = isMobile()
const faqQuestions = [
    { title: 'What is Just Trade It?', link: '/faq/what-is-just-trade-it' },
    { title: 'How does Just Trade It benefit users?', link: '/faq/just-trade-it-benefits' },
    { title: 'What are the Buy and Sell Signals and How to Use Them?', link: '/faq/buy-sell-signals' },
    { title: 'How does the JTI AI Model work?', link: '/faq/jti-ai-model' },
    { title: 'What do the tags like "Resilient" and "Win Rate High" mean?', link: '/faq/tags-meaning' },
]


const FAQ = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refObserver, inView] = useInView({
        triggerOnce: true,
        threshold: IS_MOBILE ? IN_VIEW_MOBILE : IN_VIEW,
    })

    const springRefTitle = useSpringRef()
    const springTitle = useSpring({
        ref: springRefTitle,
        config: { duration: 600 },
        from: { opacity: inView ? 0 : 1, },
        to: {
            opacity: inView ? 1 : 0
        },
    })

    const springRefList = useSpringRef()
    const springList = useSpring({
        ref: springRefList,
        config: { duration: 400 },
        from: { opacity: inView ? 0 : 1, },
        to: {
            opacity: inView ? 1 : 0,
        },
    })

    useChain([springRefTitle, springRefList], [0, 1], 600)


    return (
        <div className="page" ref={refObserver}>
            <div className="landing-content">
                <Row justify="space-around">
                    <animated.div className='enrollement-title' style={{ ...springTitle, }}>
                        <span style={{ color: COLOR_PRIMARY }}>Frequently Asked Questions</span>
                    </animated.div>
                </Row>
                <Row gutter={{ xs: 0, sm: 90 }} justify="space-around">
                    <animated.div style={{ ...springList, }}>
                        <List size='large' bordered className={styles['list-questions']}
                            itemLayout="horizontal"
                            dataSource={faqQuestions}
                            renderItem={(item) => (
                                <List.Item>
                                    <Link className="none-color-link" to={item.link}>{item.title}</Link>
                                </List.Item>
                            )}
                        />
                    </animated.div>
                </Row>
            </div>
        </div>
    )
}

export default FAQ;