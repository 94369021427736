import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Col, Image, Row, Typography } from 'antd';
import { useInView } from 'react-intersection-observer';
import { SignupButton } from '../../../components/buttons/signup-button';
import { isMobile } from '../../../utils/utils';
import { gutter, IN_VIEW, IN_VIEW_MOBILE } from '../../Home';


// SHARED VARIABLES //
const { Title, Text } = Typography
const IS_MOBILE = isMobile()


const FinancialCharts = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refP1, inViewP1] = useInView({
        triggerOnce: true,

        // Percentage of the HTML element visibility to trigger the animation
        threshold: IS_MOBILE ? IN_VIEW_MOBILE : IN_VIEW,
    })

    const springRefDescription = useSpringRef()
    const springDescription = useSpring({
        ref: springRefDescription,
        config: { duration: 400 },
        from: { opacity: inViewP1 ? 0 : 1, },
        to: {
            opacity: inViewP1 ? 1 : 0, // Set opacity based on visibility
            transform: inViewP1 ? 'translateX(0)' : 'translateX(100px)'
        },
    })

    const springRefImage = useSpringRef()
    const springImage = useSpring({
        ref: springRefImage,
        config: { duration: 1000 },
        from: { opacity: inViewP1 ? 0 : 1, },
        to: {
            opacity: inViewP1 ? 1 : 0
        },
    })

    useChain(IS_MOBILE ? [springRefImage, springRefDescription] : [springRefDescription, springRefImage], [0, 1], IS_MOBILE ? 1000 : 400)


    return (
        <div className="page" ref={refP1}>
            <div className="landing-content">
                <Row gutter={gutter}>
                    <Col xs={24} md={16}>
                        <animated.div className='img-background' style={{ ...springImage, }}>
                            <Image src='/landing_financial_charts.png' alt='Financials Charts' preview={false} />
                        </animated.div>
                    </Col>
                    <Col xs={24} md={8}>
                        <animated.div className="text-container" style={{ ...springDescription, }}>
                            <Title level={1} className='page-title'>Beautiful Financial Charts</Title>
                            <Text className='page-description'>
                                Explore visually <b>stunning charts</b> that illuminate company <b>financial statements</b> and <b>fundamentals</b>,
                                making it easier than ever to spot <b>key metrics</b> and <b>trends</b>.
                                With our app, you'll make <b>informed investment decisions</b> with <b>confidence</b> and data visualization that makes sense.
                            </Text>
                            {!IS_MOBILE &&
                                <div>
                                    <SignupButton className="landing-signup" type='primary' label='Explore Financial Charts Free' />
                                </div>
                            }
                        </animated.div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default FinancialCharts;