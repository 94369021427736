import { Descriptions, Image, Modal, Space, Typography } from 'antd';
import { displayData, formatNumberToB, formatPourcent, getAITag, getLogoUrl, isMobile } from '../utils/utils';
import { IconInfoDivYield, IconInfoExDivDate, IconInfoShortRatio, IconWarnShortRatio } from './Icons';
import { TagClassResilient, TagClassYoung } from './Tags';
import { useState } from 'react';
import Link from 'antd/es/typography/Link';
import ModalBasic from './modals/ModalBasic';

const { Paragraph, Text } = Typography;
const { Item, } = Descriptions;


const TickerInfoDescStock = ({ tkrInfos }) => {

    const [isModalVisible, setIsModalVisible] = useState(false)

    return (<>
        <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2 }} size={isMobile() ? 'small' : 'default'}>
            <Item label="CEO" span={1}>{displayData(tkrInfos.ceoName)}</Item>
            <Item span={2}>
                <Text style={{ cursor: 'pointer', width: '94%' }} ellipsis={{ suffix: <a><i> more</i></a> }} onClick={() => setIsModalVisible(true)}>
                    {tkrInfos.description}
                </Text>
            </Item>
            <Item label="Industry">{tkrInfos.industry}</Item>
            <Item label="Exchange">{tkrInfos.exchange}</Item>
            <Item label="Market Capitalization">{formatNumberToB(tkrInfos.marketCap)}</Item>
            <Item label={<>Dividends Yield<IconInfoDivYield /></>}>{displayData(tkrInfos.dividendYield) === '-' ? '-' : formatPourcent(tkrInfos.dividendYield)}</Item>
            <Item label={<>Ex-Dividend Date<IconInfoExDivDate /></>}>{displayData(tkrInfos.exDividendDate)}</Item>
            <Item label={<>Short Ratio<IconInfoShortRatio /></>}>
                {displayData(tkrInfos.shortRatio) === '-' ? '-' :
                    tkrInfos.shortRatio < 4 ? (
                        <>{tkrInfos.shortRatio}%</>
                    ) : (
                        // lineHeight: '0.8em' > Hack to make the icon sup working
                        <span style={{ lineHeight: '0.8em' }}>{tkrInfos.shortRatio}%<IconWarnShortRatio /></span>
                    )}
            </Item>
        </Descriptions>

        <ModalBasic open={isModalVisible} setOpen={setIsModalVisible} icon={getLogoUrl(tkrInfos.ticker)}
            title={tkrInfos.shortName}>
            <Paragraph className="modal-paragraph reading">
                {tkrInfos.description}
            </Paragraph>
        </ModalBasic>
    </>)
}

export default TickerInfoDescStock;