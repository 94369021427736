import { Typography } from 'antd';
import React from 'react';
import MyBreadcrumb from '../../components/Breadcrumb';
import PageTitle1 from '../../components/PageTitle1';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import { FAQ_CRUMB } from '../FAQ';
import { Link } from 'react-router-dom';


const { Paragraph } = Typography;

const title = "How does the JTI AI Model work?"

const JTIAIModel = ({ onlyContent = false }) => {

    const content = <>
        <Paragraph className='blog-paragraph'>
            The JTI AI Model simplifies trading by analyzing asset behaviors, patterns, volatility, and market indicators like support and resistance levels.
            Using historical data, it identifies optimal trading opportunities, helping traders make informed decisions with confidence.
        </Paragraph>
        <Paragraph className='blog-paragraph'>
            To learn more, please visit: <Link to="/jti-ai-model" >JTI AI Model</Link>
        </Paragraph>
    </>

    if (onlyContent)
        return content

    return (<>
        <MetaTags
            title={title}
            description="The JTI AI Model analyzes historical asset behavior, market trends, and macroeconomic indicators to identify optimal trading opportunities. It uses AI to evaluate patterns, support, resistance, and volatility for data-driven insights."
            keywords="JTI AI Model, AI-powered trading, trading model, stock market insights, market trends, trading opportunities"
        />

        <MyBreadcrumb items={FAQ_CRUMB.concat([
            {
                title: title,
            }
        ])} />

        <PageTitle1> • {title}</PageTitle1>

        {content}
    </>)
}

export default JTIAIModel;