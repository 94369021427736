import { Image, Layout, Table, Typography } from 'antd';
import React from 'react';
import BlogPostNavigationBottom from '../../components/BlogPostNavigationBottom';
import BlogTitle1 from '../../components/BlogTitle1';
import PageTitle2 from '../../components/PageTitle2';
import PageTitle3 from '../../components/PageTitle3';
import '../../styles/Blog.css';
import MetaTags from '../../MetaTags';
import { BLOG_CRUMB, blogPosts } from '../Blog';
import MyBreadcrumb from '../../components/Breadcrumb';
import { SignupButton } from "../../components/buttons/signup-button";
import BlogList from '../../components/blog/BlogList';


const { Paragraph } = Typography;

const relatedPosts = [4, 9, 10]

const CommonStocksVSPreferredStocks = ({ title, imgFile, updatedDate }) => {
    // Define the columns for the table
    const columns = [
        {
            title: "Feature",
            dataIndex: "feature",
            key: "feature",
        },
        {
            title: "Common Stocks",
            dataIndex: "common",
            key: "common",
        },
        {
            title: "Preferred Stocks",
            dataIndex: "preferred",
            key: "preferred",
        },
    ];

    // Define the data for the table
    const data = [
        {
            key: "1",
            feature: "Voting Rights",
            common: "Yes, shareholders can vote on company matters.",
            preferred: "No, shareholders do not have voting rights.",
        },
        {
            key: "2",
            feature: "Dividends",
            common: "Dividends are variable and not guaranteed.",
            preferred: "Fixed dividends, paid before common stock dividends.",
        },
        {
            key: "3",
            feature: "Risk Level",
            common: "Higher risk, as common stockholders are last in line for assets.",
            preferred: "Lower risk, as preferred stockholders are paid before common stockholders.",
        },
        {
            key: "4",
            feature: "Potential Return",
            common: "Higher potential for capital appreciation but more volatile.",
            preferred: "More stable income but lower potential for capital growth.",
        },
    ];

    return (
        <Layout className="site-layout-content-fixed site-layout-content-blog">
            <MetaTags
                title="Common Stocks vs. Preferred Stocks: Key Differences and How to Choose"
                description="Discover the key differences between common and preferred stocks. Learn which is better for your portfolio based on growth potential, stability, and dividends."
                keywords="common stocks, preferred stocks, stock market investing, dividend stocks, growth investing, stable income, portfolio diversification" />

            <MyBreadcrumb items={BLOG_CRUMB.concat([
                {
                    title: title,
                }
            ])} />

            <BlogTitle1>
                • {title}
                <div className='blog-title-meta'>{updatedDate}</div>
            </BlogTitle1>

            <Paragraph className='blog-paragraph'>
                <Image width="100%" src={imgFile} alt="Stock market graph showcasing growth potential of common and preferred stocks" />
            </Paragraph>

            <Paragraph className='blog-paragraph'>
                When it comes to stock market investing, understanding the different types of stocks available is crucial. Two of the most common types are common stocks and preferred stocks. While both represent ownership in a company, they come with different rights, benefits, and risks. In this blog, we’ll compare the two and help you decide which one suits your investment strategy.
            </Paragraph>

            <PageTitle2>What Are Common Stocks?</PageTitle2>
            <Paragraph className='blog-paragraph'>
                Common stocks are the most widely traded type of stock, and owning them means you have a stake in a company’s success. When you buy common stocks, you become a partial owner of the company and can benefit from price appreciation and dividends.
            </Paragraph>
            <ul className='blog-list'>
                <li>
                    <b>Voting Rights:</b> Common shareholders have voting rights, typically on issues like the election of board members.
                </li>
                <li>
                    <b>Dividends:</b> Common shareholders may receive dividends, but they’re not guaranteed. The company can decide to reinvest profits or distribute them to shareholders.
                </li>
                <li>
                    <b>Higher Risk, Higher Reward:</b> Common stockholders are last in line for claims in case of liquidation, meaning they face higher risk. However, if the company performs well, common stockholders benefit from higher capital appreciation.
                </li>
            </ul>
            <Paragraph className='blog-paragraph'>
                <i>Example:</i> Apple (AAPL) is a well-known company that offers common stock. Investors in Apple’s common stock benefit from the company’s growth, stock price appreciation, and dividend payments.
            </Paragraph>

            <PageTitle2>What Are Preferred Stocks?</PageTitle2>
            <Paragraph className='blog-paragraph'>
                Preferred stocks are a type of stock that gives investors preference over common stockholders when it comes to dividends and claims on assets during liquidation. While they don’t typically offer voting rights, they do come with more stability and predictability.
            </Paragraph>
            <ul className='blog-list'>
                <li>
                    <b>No Voting Rights:</b> Preferred shareholders generally don’t have voting rights, so they don’t influence company decisions.
                </li>
                <li>
                    <b>Guaranteed Dividends:</b> Preferred stockholders receive fixed dividends, which are paid out before common stock dividends. These dividends can be an attractive option for income-seeking investors.
                </li>
                <li>
                    <b>Lower Risk:</b> Preferred stockholders have a higher claim on assets in the event of liquidation, making them less risky than common stockholders. However, they offer lower potential for capital appreciation.
                </li>
            </ul>
            <Paragraph className='blog-paragraph'>
                <i>Example:</i> The Coca-Cola Company (KO) is a popular example of a company that offers preferred stocks. Investors in Coca-Cola’s preferred stocks can rely on a steady stream of dividends, but they don’t have a say in the company’s management.
            </Paragraph>

            <PageTitle2>Common Stocks vs. Preferred Stocks: The Key Differences</PageTitle2>
            <Paragraph className='blog-paragraph'>
                <Table columns={columns} dataSource={data} pagination={false} bordered />
            </Paragraph>

            <PageTitle2>Which One Should You Choose?</PageTitle2>
            <Paragraph className='blog-paragraph'>
                <b>If you’re looking for growth:</b> Common stocks may be more suitable for you. With companies like Tesla (TSLA) offering high growth potential, common stocks can provide impressive returns over the long term.
            </Paragraph>
            <Paragraph className='blog-paragraph'>
                <b>If you want stability and regular income:</b> Preferred stocks are better suited for income-focused investors. Companies like AT&T (T) offer preferred stock options with predictable dividend payments.
            </Paragraph>
            <Paragraph className='blog-paragraph'>
                Both common and preferred stocks can play an important role in a diversified portfolio. It’s essential to consider your investment goals, risk tolerance, and income needs when making your decision.
            </Paragraph>

            <PageTitle2>Conclusion</PageTitle2>
            <Paragraph className='blog-paragraph'>
                Choosing between common and preferred stocks comes down to your financial goals. Common stocks are ideal for investors seeking growth and capital appreciation, while preferred stocks are more suited for those looking for steady income with less volatility. By understanding the key differences and benefits, you can make more informed decisions about which type of stock fits your portfolio.
            </Paragraph>
            <Paragraph className='blog-paragraph'>
                Ready to invest? Research companies like Apple and Coca-Cola to explore your options, or consult with a financial advisor to build a portfolio that suits your needs.
            </Paragraph>

            <SignupButton className="blog-cta" type='primary' label='Start Looking At Common And Preferred Stocks' />

            <PageTitle3>Related Blog Posts</PageTitle3>
            <BlogList blogs={blogPosts.filter((_, index) => relatedPosts.includes(index))} />

            <BlogPostNavigationBottom />
        </Layout>
    );
};

export default CommonStocksVSPreferredStocks;