import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { GRID_GUTTER } from '../JTIConst';
import { getAITag, isMobile } from '../utils/utils';
import { TagClassResilient, TagClassYoung, TagFinStrong, TagFinWeak } from './Tags';


const IS_MOBILE = isMobile()


const TickerTags = ({ tkrInfos, efficiency, tourRef }) => {

    // Define interval
    var efficiency = tkrInfos.oneDay
    // if (props.interval == 'hourly') {
    //     efficiency = tkrInfos.oneHour
    // }

    // Define tags
    const tagResilient = tkrInfos.tags?.resilient ? <TagClassResilient light={true} /> : null;
    const tagYoung = tkrInfos.tags?.young ? <TagClassYoung light={true} /> : null;

    const tagFinancialStrong = tkrInfos.tags?.financialStrong ? <TagFinStrong light={true} /> : null;
    const tagFinancialWeak = tkrInfos.tags?.financialWeak ? <TagFinWeak light={true} /> : null;

    var modelEfficiencyGood = ''
    var modelEfficiencyWarn = ''
    if (efficiency.total_win_percent >= 70)
        modelEfficiencyGood = getAITag(efficiency.total_win_percent, true);
    else
        modelEfficiencyWarn = getAITag(efficiency.total_win_percent, true);

    // Define goodTags and warnTags with fallback values
    const goodTags = modelEfficiencyGood || tagResilient || tagFinancialStrong ? (
        <>
            {modelEfficiencyGood}
            {tagResilient}
            {tagFinancialStrong}
        </>
    ) : "-";

    const warnTags = modelEfficiencyWarn || tagYoung || tagFinancialWeak ? (
        <>
            {modelEfficiencyWarn}
            {tagYoung}
            {tagFinancialWeak}
        </>
    ) : "-";

    const containerStyle = { padding: '1em', borderRadius: '7px', backgroundColor: 'rgba(232, 232, 232, 0.3)', width: '100%', display: 'flex' }
    const iconStyle = { fontSize: "1.5em", color: "grey", marginRight: "0.5em" }

    return (
        <Row id='tags' ref={tourRef} gutter={GRID_GUTTER} style={{ marginBottom: IS_MOBILE ? '1rem' : '1.9rem' }}>
            <Col xs={24} md={12}>
                <div style={containerStyle}>
                    <span style={iconStyle}>
                        <CheckCircleOutlined /> :
                    </span>
                    <Space wrap>{goodTags}</Space>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div style={containerStyle}>
                    <span style={iconStyle}>
                        <WarningOutlined /> :
                    </span>
                    <Space wrap>{warnTags}</Space>
                </div>
            </Col>
        </Row>
    )
}

export default TickerTags;