import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Checkbox, Descriptions, Image, Layout, Radio, Select, Space, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { IconInHoldings, IconLoading, IconSignalBuyL1, IconSignalBuyL2, IconSignalOff, IconSignalSellL1, IconSignalSellL2, IconWarnShortRatio } from '../components/Icons';
import PageTitle1 from '../components/PageTitle1';
import { TagAIAverage, TagAIGood, TagAIHigh, TagAILow, TagClassResilient, TagFinStrong } from '../components/Tags';
import UserContext from '../components/UserContext';
import { AssetTypeOptions, STORE_SCREENER_FILTER_ASSET_TYPE, STORE_SCREENER_FILTER_BSL1, STORE_SCREENER_FILTER_BSL2, STORE_SCREENER_FILTER_EXCHANGE, STORE_SCREENER_FILTER_INDUSTRY, STORE_SCREENER_FILTER_SSL1, STORE_SCREENER_FILTER_SSL2, STORE_SCREENER_FILTER_TAG_FIN_STRONG, STORE_SCREENER_FILTER_TAG_RESILIENT, STORE_SCREENER_FILTER_TAG_WIN_RATE, WIN_RATE_LIMIT_AVG, WIN_RATE_LIMIT_GOOD, WIN_RATE_LIMIT_HIGH } from '../JTIConst';
import MetaTags from "../MetaTags";
import { getTickerFull } from "../services/ticker.service";
import { getColumnSearchProps } from '../utils/CustomizeTable';
import { arrayIntersection, displayData, formatNumberToB, formatPourcent, getLogoUrl, getTagList, navigateToTickerObject } from '../utils/utils';

const { Text } = Typography;
const { Item, } = Descriptions;


const Screener = () => {

    const { getAccessTokenSilently } = useAuth0();
    const { userContext, doIHold } = useContext(UserContext);
    const [tableData, setTableData] = useState(null);
    const [filterIndustryOpts, setFilterIndustryOpts] = useState([]);
    const [filterExchangeOpts, setFilterExchangeOpts] = useState([]);
    const paginationOptions = {
        defaultPageSize: 50,
        showSizeChanger: true,
    }

    // Filter
    const [loading, setLoading] = useState(true)
    const [filterAssetType, setFilterAssetType] = useState(localStorage.getItem(STORE_SCREENER_FILTER_ASSET_TYPE) ? localStorage.getItem(STORE_SCREENER_FILTER_ASSET_TYPE) : 'ALL')
    const [filterBSL1, setFilterBSL1] = useState(localStorage.getItem(STORE_SCREENER_FILTER_BSL1)?.match('true') ? true : false)
    const [filterBSL2, setFilterBSL2] = useState(localStorage.getItem(STORE_SCREENER_FILTER_BSL2)?.match('true') ? true : false)
    const [filterSSL1, setFilterSSL1] = useState(localStorage.getItem(STORE_SCREENER_FILTER_SSL1)?.match('true') ? true : false)
    const [filterSSL2, setFilterSSL2] = useState(localStorage.getItem(STORE_SCREENER_FILTER_SSL2)?.match('true') ? true : false)
    const [filterTagResilient, setFilterTagResilient] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_RESILIENT)?.match('true') ? true : false)
    const [filterTagFinStrong, setFilterTagFinStrong] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_FIN_STRONG)?.match('true') ? true : false)
    const [filterWinRate, setFilterWinRate] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_WIN_RATE) ? localStorage.getItem(STORE_SCREENER_FILTER_TAG_WIN_RATE) : 'low')
    const [filterIndustry, setFilterIndustry] = useState(() => {
        if (localStorage.getItem(STORE_SCREENER_FILTER_INDUSTRY) !== null)
            return JSON.parse(localStorage.getItem(STORE_SCREENER_FILTER_INDUSTRY))
        else
            return []
    })
    const [filterExchange, setFilterExchange] = useState(() => {
        if (localStorage.getItem(STORE_SCREENER_FILTER_EXCHANGE) !== null)
            return JSON.parse(localStorage.getItem(STORE_SCREENER_FILTER_EXCHANGE))
        else
            return []
    })

    const [searchTriggered, setSearchTriggered] = useState(false); // New state to track search trigger

    const symbolSearchRef = useRef(null);

    const toggleFilterAssetType = (evt) => {
        const selectedAsset = evt.target.value
        setFilterAssetType(selectedAsset)
        localStorage.setItem(STORE_SCREENER_FILTER_ASSET_TYPE, selectedAsset)
    }
    const toggleFilterBSL1 = (e) => {
        setFilterBSL1(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_BSL1, e.target.checked)
    }
    const toggleFilterBSL2 = (e) => {
        setFilterBSL2(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_BSL2, e.target.checked)
    }
    const toggleFilterSSL1 = (e) => {
        setFilterSSL1(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_SSL1, e.target.checked)
    }
    const toggleFilterSSL2 = (e) => {
        setFilterSSL2(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_SSL2, e.target.checked)
    }
    const toggleFilterTagResilient = (e) => {
        setFilterTagResilient(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_RESILIENT, e.target.checked)
    }
    const toggleFilterTagFinStrong = (e) => {
        setFilterTagFinStrong(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_FIN_STRONG, e.target.checked)
    }
    const updIndustryFilter = (e) => {
        setFilterIndustry(e)
        localStorage.setItem(STORE_SCREENER_FILTER_INDUSTRY, JSON.stringify(e))
    }
    const updExchangeFilter = (e) => {
        setFilterExchange(e)
        localStorage.setItem(STORE_SCREENER_FILTER_EXCHANGE, JSON.stringify(e))
    }

    // Handle selection change in win rate radio group
    const handleWinRateChange = (e) => {
        setFilterWinRate(e.target.value)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_WIN_RATE, e.target.value)
    }

    // Handle search button click
    const handleSearchClick = () => {
        setSearchTriggered(true)
    }


    // EFFECTS //

    useEffect(() => {

        setLoading(true)

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getTickerFull(accessToken)

            if (error)
                return

            // Remove stocks indexes
            const tkrFullList = data.filter(item => !item.ticker.startsWith('^'))


            // SET FILTERS SELECTED LISTS //

            // Use a Set to store unique industries
            const uniqueIndustries = new Set();
            tkrFullList.forEach((item) => {
                if (item.industry)
                    uniqueIndustries.add(item.industry)
            })
            const optsIndustries = []
            uniqueIndustries.forEach((industry) => {
                optsIndustries.push({
                    label: industry,
                    value: industry
                })
            })
            setFilterIndustryOpts(optsIndustries)

            // Use a Set to store unique echanges
            const uniqueExchanges = new Set();
            tkrFullList.forEach((item) => {
                if (item.exchange)
                    uniqueExchanges.add(item.exchange)
            })
            const optsExchanges = []
            uniqueExchanges.forEach((exchange) => {
                optsExchanges.push({
                    label: exchange,
                    value: exchange
                })
            })
            setFilterExchangeOpts(optsExchanges)


            // If search button not hit > exit
            if (!searchTriggered) {
                setLoading(false)
                return
            }


            // FILTERING //

            var tkrListFiltered = tkrFullList

            // Asset type
            if (filterAssetType !== 'ALL') {
                tkrListFiltered = tkrListFiltered.filter(item => item.assetType === filterAssetType)
            }

            // Signals
            if (filterBSL1 || filterBSL2 || filterSSL1 || filterSSL2) {

                let listFilterBSL1 = []
                if (filterBSL1) {
                    listFilterBSL1 = tkrFullList.filter(item => item.oneDay?.activeSignalBuyL1 == 1)
                }
                let listFilterBSL2 = []
                if (filterBSL2) {
                    listFilterBSL2 = tkrFullList.filter(item => item.oneDay?.activeSignalBuyL2 == 1)
                }
                let listFilterSSL1 = []
                if (filterSSL1) {
                    listFilterSSL1 = tkrFullList.filter(item => item.oneDay?.activeSignalSellL1 == 1)
                }
                let listFilterSSL2 = []
                if (filterSSL2) {
                    listFilterSSL2 = tkrFullList.filter(item => item.oneDay?.activeSignalSellL2 == 1)
                }

                const signalsList = listFilterBSL1.concat(listFilterBSL2, listFilterSSL1, listFilterSSL2)
                tkrListFiltered = arrayIntersection({ key: "ticker" }, tkrListFiltered, signalsList)
            }

            // Win rate
            if (filterWinRate && filterWinRate !== "low") {

                let listWinRate = []
                if (filterWinRate === "average") {
                    listWinRate = tkrListFiltered.filter(item => item.oneDay?.total_win_percent >= WIN_RATE_LIMIT_AVG)
                } else if (filterWinRate === "good") {
                    listWinRate = tkrListFiltered.filter(item => item.oneDay?.total_win_percent >= WIN_RATE_LIMIT_GOOD)
                } else {
                    // High
                    listWinRate = tkrListFiltered.filter(item => item.oneDay?.total_win_percent >= WIN_RATE_LIMIT_HIGH)
                }

                tkrListFiltered = arrayIntersection({ key: "ticker" }, tkrListFiltered, listWinRate)
            }

            // Resilient
            if (filterTagResilient) {
                const listFilterTagResilient = tkrListFiltered.filter(item => item.tags?.resilient == true)
                tkrListFiltered = arrayIntersection({ key: "ticker" }, tkrListFiltered, listFilterTagResilient)
            }

            // Financial strong
            if (filterTagFinStrong) {
                const listFilterTagFinanStrong = tkrListFiltered.filter(item => item.tags?.financialStrong == true)
                tkrListFiltered = arrayIntersection({ key: "ticker" }, tkrListFiltered, listFilterTagFinanStrong)
            }

            // Industry
            if (filterIndustry.length > 0) {
                tkrListFiltered = tkrListFiltered.filter(item => filterIndustry.includes(item.industry))
            }

            // Exchange
            if (filterExchange.length > 0) {
                tkrListFiltered = tkrListFiltered.filter(item => filterExchange.includes(item.exchange))
            }

            const table = []
            for (const tkrInfos of tkrListFiltered) {

                // Avoid duplicates
                if (table.find((i) => i.key == tkrInfos.ticker) == undefined) {

                    table.push({
                        key: tkrInfos.ticker,
                        symbol: tkrInfos.ticker,
                        shortName: tkrInfos.shortName,
                        industry: tkrInfos.industry,
                        tags: getTagList(tkrInfos),
                        marketCap: tkrInfos.marketCap,
                        dividendYield: tkrInfos.dividendYield,
                        exDividendDate: tkrInfos.exDividendDate,
                        shortRatio: tkrInfos.shortRatio,
                        totalWinPerct: tkrInfos.oneDay?.total_win_percent,
                        roiAVGAll: tkrInfos.oneDay?.roi_avg_all,
                        activeSignalBuyL1: tkrInfos.oneDay?.activeSignalBuyL1,
                        activeSignalBuyL2: tkrInfos.oneDay?.activeSignalBuyL2,
                        activeSignalSellL1: tkrInfos.oneDay?.activeSignalSellL1,
                        activeSignalSellL2: tkrInfos.oneDay?.activeSignalSellL2,
                    })
                }
            }

            setTableData(table)
            setLoading(false)
            setSearchTriggered(false); // Reset search trigger
        }
        fetchData()
    }, [searchTriggered])

    // userContext needs to be setted otherwise error
    if (userContext === null) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    // FILTERS MENU //

    const tagCheckboxStyle = { marginRight: '0.5em' }
    const filtersMenu =
        <Card title='Filters' className='card grid-bottom'>
            <Descriptions column={{ xs: 1, sm: 1, md: 4, lg: 4, xl: 4, xxl: 4 }} labelStyle={{ margin: '0 0 0.7rem 0', color: 'black', alignItems: 'center' }} contentStyle={{ margin: '0 0 0.7rem 0' }}>
                <Item label='• Asset Type' span={{ md: 2 }}>
                    <Radio.Group options={AssetTypeOptions} defaultValue={filterAssetType} optionType="button" buttonStyle="solid" onChange={toggleFilterAssetType} />
                </Item>
                <Item label='• Buy Signals'>
                    <Space>
                        <Checkbox id='filterBSL1' checked={filterBSL1} onChange={toggleFilterBSL1} /> L1 <IconSignalBuyL1 />
                        <Checkbox id='filterBSL2' checked={filterBSL2} onChange={toggleFilterBSL2} /> L2 <IconSignalBuyL2 />
                    </Space>
                </Item>
                <Item label='• Sell Signals'>
                    <Space>
                        <Checkbox id='filterSSL1' checked={filterSSL1} onChange={toggleFilterSSL1} /> L1 <IconSignalSellL1 />
                        <Checkbox id='filterSSL2' checked={filterSSL2} onChange={toggleFilterSSL2} /> L2 <IconSignalSellL2 />
                    </Space>
                </Item>
                <Item label='• AI Tags' span={{ md: 4 }}>
                    <Space size='small' wrap>
                        <Radio.Group onChange={handleWinRateChange} value={filterWinRate}>
                            <Radio id='filterWinRateLow' value="low"><TagAILow /></Radio>
                            <Radio id='filterWinRateAvg' value="average"><TagAIAverage /></Radio>
                            <Radio id='filterWinRateGood' value="good"><TagAIGood /></Radio>
                            <Radio id='filterWinRateHigh' value="high"><TagAIHigh /></Radio>
                        </Radio.Group>
                        <div>
                            <Checkbox id='filterResilient' checked={filterTagResilient} onChange={toggleFilterTagResilient} style={tagCheckboxStyle} /><TagClassResilient />
                        </div>
                        <div>
                            <Checkbox id='filterFinStrong' checked={filterTagFinStrong} onChange={toggleFilterTagFinStrong} style={tagCheckboxStyle} /><TagFinStrong />
                        </div>
                    </Space>
                </Item>
                <Item label='• Industry' span={{ md: 2 }}>
                    <Select style={{ width: '95%' }} placeholder="Select industries"
                        mode="multiple"
                        allowClear
                        defaultValue={filterIndustry}
                        onChange={updIndustryFilter}
                        options={filterIndustryOpts}
                    />
                </Item>
                <Item label='• Exchange' span={{ md: 2 }}>
                    <Select style={{ width: '95%' }} placeholder="Select exchanges"
                        mode="multiple"
                        allowClear
                        defaultValue={filterExchange}
                        onChange={updExchangeFilter}
                        options={filterExchangeOpts}
                    />
                </Item>
            </Descriptions>
            <Button id='filterSearchBtn' type="primary" onClick={handleSearchClick} style={{ margin: '1.5rem 1.8rem 0 0', float: 'right' }}>
                Search
            </Button>
        </Card>

    return (
        <Layout className="site-layout-content">
            <MetaTags title="Just Trade It: Screener" />

            <PageTitle1> • AI Screener</PageTitle1>

            {filtersMenu}

            {loading ?
                <IconLoading />
                :
                <>
                    {/* TABLE */}
                    <Card id='screenerTable' className='card'>
                        <Table className='table' size='small' dataSource={tableData} pagination={paginationOptions} rowClassName={'table-row-pointer'}
                            onRow={(record, rowIndex) => navigateToTickerObject(record.key)}>
                            <Column title="Symbol" dataIndex="symbol" key="symbol" {...getColumnSearchProps(symbolSearchRef, 'symbol')}
                                render={(_, record) => (
                                    <Space align="center">
                                        <Image src={getLogoUrl(record.symbol)} width={16} preview={false} />
                                        <Text>{record.symbol} {doIHold(record.symbol) && <IconInHoldings />}</Text>
                                    </Space>)} />

                            <Column title="Name" dataIndex="shortName" key="shortName" responsive={['md']} sorter={(a, b) => a.shortName.localeCompare(b.shortName)} />
                            <Column title="Industry" dataIndex="industry" key="industry" responsive={['md']} sorter={(a, b) => a.industry.localeCompare(b.industry)} />
                            <Column title={<><span className='ai-logo'>AI</span> Tags</>} align='left' dataIndex="tags" key="tags" responsive={['sm']}
                                sorter={(a, b) => renderToString(a.tags).localeCompare(renderToString(b.tags))} sortDirections={['descend', 'ascend']} />
                            render={(_, record) => formatNumberToB(record.marketCap)} />
                            
                            <Column title="Market Cap" align='center' dataIndex="marketCap" key="marketCap" responsive={['xl']}
                                sorter={(a, b) => a.marketCap - b.marketCap}
                                render={(_, record) => formatNumberToB(record.marketCap)} />
                            
                            <Column title="Div. Yield" align='center' dataIndex="dividendYield" key="dividendYield" width='5%' responsive={['sm']}
                                sorter={(a, b) => a.dividendYield - b.dividendYield}
                                render={(_, record) =>
                                    displayData(record.dividendYield) === '-' ? '-' :
                                        formatPourcent(record.dividendYield)} />
                            <Column title="Ex-Div. Date" align='center' dataIndex="exDividendDate" key="exDividendDate" width='8%' responsive={['sm']}
                                render={(_, record) => displayData(record.exDividendDate)} />

                            <Column title="Short Ratio" align='center' dataIndex="shortRatio" key="shortRatio" responsive={['sm']}
                                sorter={(a, b) => a.shortRatio - b.shortRatio}
                                render={(_, record) =>
                                    displayData(record.shortRatio) === '-' ? '-' :
                                        record.shortRatio < 4 ? record.shortRatio + '%' : (<>{record.shortRatio + '%'}<IconWarnShortRatio /></>)} />

                            <Column title="% Win" align='center' dataIndex="totalWinPerct" key="totalWinPerct" responsive={['sm']}
                                sorter={(a, b) => a.totalWinPerct - b.totalWinPerct} sortDirections={['descend', 'ascend']}
                                render={(_, record) => record.totalWinPerct?.toFixed(2) + '%'} />
                            <Column title="AVG ROI" align='center' dataIndex="roiAVGAll" key="roiAVGAll" responsive={['sm']}
                                sorter={(a, b) => a.roiAVGAll - b.roiAVGAll} sortDirections={['descend', 'ascend']}
                                render={(_, record) => record.roiAVGAll?.toFixed(2) + '%'} />

                            <ColumnGroup title='Active Buy Signal'>
                                <Column title={<><IconSignalBuyL1 /> L1</>} align='center' dataIndex="activeSignalBuyL1" key="activeSignalBuyL1"
                                    render={(_, record) => record.activeSignalBuyL1 ? <IconSignalBuyL1 /> : <IconSignalOff />}
                                    sorter={(a, b) => a.activeSignalBuyL1 - b.activeSignalBuyL1} sortDirections={['descend', 'ascend']}
                                />
                                <Column title={<><IconSignalBuyL2 /> L2</>} align='center' dataIndex="activeSignalBuyL2" key="activeSignalBuyL2"
                                    sorter={(a, b) => a.activeSignalBuyL2 - b.activeSignalBuyL2} sortDirections={['descend', 'ascend']}
                                    render={(_, record) => record.activeSignalBuyL2 ? <IconSignalBuyL2 /> : <IconSignalOff />} />
                            </ColumnGroup>

                            <ColumnGroup title='Active Sell Signal'>
                                <Column title={<><IconSignalSellL1 /> L1</>} align='center' dataIndex="activeSignalSellL1" key="activeSignalSellL1"
                                    render={(_, record) => record.activeSignalSellL1 ? <IconSignalSellL1 /> : <IconSignalOff />}
                                    sorter={(a, b) => a.activeSignalSellL1 - b.activeSignalSellL1} sortDirections={['descend', 'ascend']}
                                />
                                <Column title={<><IconSignalSellL2 /> L2</>} align='center' dataIndex="activeSignalSellL2" key="activeSignalSellL2"
                                    render={(_, record) => record.activeSignalSellL2 ? <IconSignalSellL2 /> : <IconSignalOff />}
                                    sorter={(a, b) => a.activeSignalSellL2 - b.activeSignalSellL2} sortDirections={['descend', 'ascend']}
                                />
                            </ColumnGroup>
                        </Table>
                    </Card>
                    <div className='legend-box'>
                        <Text className='legend-text' italic>The AI Model data is for the daily chart.</Text>
                    </div>
                </>}
        </Layout>
    )
}

export default Screener