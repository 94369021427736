import { Divider, Image, Layout, Typography } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import BlogPostNavigationBottom from '../../components/BlogPostNavigationBottom';
import BlogTitle1 from '../../components/BlogTitle1';
import MyBreadcrumb from '../../components/Breadcrumb';
import { SignupButton } from '../../components/buttons/signup-button';
import JTIAnchor from '../../components/JTIAnchor';
import PageTitle2 from '../../components/PageTitle2';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import { isMobile } from '../../utils/utils';
import { BLOG_CRUMB } from '../Blog';


// PARAMETERS //

const IS_MOBILE = isMobile()

// SHARED VARIABLES //

const { Text, Paragraph } = Typography;
const anchorMenu = [
    {
        key: 'introduction',
        href: '#introduction',
    },
    {
        key: 'quick-start',
        href: '#quick-start',
    },
    {
        key: 'assets',
        href: '#assets',
    },
    {
        key: 'analyze',
        href: '#analyze',
    },
    {
        key: 'watchlist',
        href: '#watchlist',
    },
    {
        key: 'mindset',
        href: '#mindset',
    },
]

const youtubeProps = {
    height: IS_MOBILE ? '260' : '400',
    width: '100%',
    allowfullscreen: 'allowfullscreen',
    style: {
        border: 'none',
    }
}


const Tutorial = ({title, imgFile, updatedDate }) => {

    return (<>
        {!isMobile() &&
            <JTIAnchor items={anchorMenu} />
        }

        <Layout className="site-layout-content-fixed site-layout-content-blog">
            <MetaTags
                title="Just Trade It: Tutorial The Swing Resilient Strategy"
                description="Trading consists of two simple actions: buy, then sell. The challenge lies in executing these actions at..." />

            <MyBreadcrumb items={BLOG_CRUMB.concat([
                {
                    title: title,
                }
            ])} />

            <BlogTitle1 id="introduction">
                • {title}
                <div className='blog-title-meta'>{updatedDate}</div>
            </BlogTitle1>

            <Paragraph className='blog-paragraph'>
                <blockquote>
                    <Text className='quote'>
                        "Trading consists of two simple actions: buy, then sell. The challenge lies in executing these actions at the right time!<br />
                        This is where Just Trade It can assist you..."
                    </Text>
                </blockquote>
            </Paragraph>

            <Paragraph className='blog-paragraph'>
                <Image width="100%" src={imgFile} alt="Board with stock market graph and strategic formulas" />
            </Paragraph>

            <Paragraph className='blog-paragraph'>
                <b>Just Trade It</b> is an AI based platform that enables users to find the right asset for investment and the right time to invest in them.
                Currently, JTI provides only the most relevant market information for investing and does not  facilitate order execution.
                To execute trades, you will need to register with a broker such as Wealthsimple or Robinhood.
            </Paragraph>

            <Paragraph className='blog-paragraph'>
                This tutorial will teach you how to execute what we call <b>"The Swing Resilient Strategy"</b>.<br />
                Here is the short video that explain how the strategy works:
                <div style={{ textAlign: 'center', marginTop: '2em' }}>
                    <iframe src="https://www.youtube.com/embed/0SghP9BFf3k" {...youtubeProps} />
                </div>
            </Paragraph>

            <Divider className='blog-divider'>QUICK START</Divider>

            <div id="quick-start">
                <Paragraph className='blog-paragraph'>
                    To start your journey with <b>"The Swing Resilient Strategy"</b>, we will follow these steps:
                </Paragraph>
                <ol className='blog-list'>
                    <li>Find the <b>resilients</b> assets</li>
                    <li>Analyze the asset with the <b>AI Chart</b></li>
                    <li>Add the asset to your <b>watchlist</b></li>
                    {/* <li>Place an order in your portfolio</li> */}
                    <li>Adopt the <b>mindset</b> of investors</li>
                </ol>
            </div>

            <div id="assets">
                <PageTitle2>Find The Resilients Assets</PageTitle2>
                <Paragraph className='blog-paragraph' style={{ textAlign: 'center' }}>
                    <iframe src="https://www.youtube.com/embed/0KQHRebcuPA" {...youtubeProps} />
                </Paragraph>
            </div>

            <div id="analyze">
                <PageTitle2>Analyze The Asset With The AI Chart</PageTitle2>
                <Paragraph className='blog-paragraph' style={{ textAlign: 'center' }}>
                    <iframe src="https://www.youtube.com/embed/YU6pX-gXMQU" {...youtubeProps} />
                </Paragraph>
            </div>

            <div id="watchlist">
                <PageTitle2>Add The Asset To Your Watchlist</PageTitle2>
                <Paragraph className='blog-paragraph' style={{ textAlign: 'center' }}>
                    <iframe src="https://www.youtube.com/embed/fk6XVqTzAQE" {...youtubeProps} />
                </Paragraph>
            </div>

            <div id="mindset">
                <PageTitle2>The Investor Mindset</PageTitle2>

                <Paragraph className='blog-paragraph'>
                    At Just Trade It, we believe that mindset plays a crucial role in becoming a successful investor, especially in the long term.<br />
                    That's why we have published a <Link to="/blog/tradechecklist" >Trade Checklist</Link> that we recommend reading each time you consider executing an order.
                    This will help wire your brain with the fundamentals of investment as a reflex.
                </Paragraph>
            </div>

            <SignupButton className="blog-cta" type='primary' label='Start With The Swing Resilient Strategy' />

            <BlogPostNavigationBottom />
        </Layout>
    </>)
}

export default Tutorial;