import { CheckOutlined, } from '@ant-design/icons';
import { theme, Layout, Typography, Col, Row, Card, List, Button, Modal, Space, Switch, Statistic, Tag, Badge } from 'antd';
import '../styles/Pricing.css';
import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SignupButton } from "../components/buttons/signup-button";
import SubscriptionForm from "../components/SubscriptionForm";
import UserContext from '../components/UserContext';
import { getJTIParameters } from '../services/jti.service';
import { COLOR_PRIMARY, GRID_GUTTER } from '../JTIConst';
import MetaTags from '../MetaTags';


const { Content } = Layout;
const { Title, Text, } = Typography;
const { Countdown } = Statistic


// PARAMETERS //

const PAYMENT_DISABLE = process.env.REACT_APP_PAYMENT_DISABLE === "true"

// Square
const REACT_APP_SQ_WEB_SDK = process.env.REACT_APP_SQ_WEB_SDK;

const SQ_SUB_MEMBER_MONTHLY_CATALOG_OBJ_ID = process.env.REACT_APP_SQ_SUB_MEMBER_MONTHLY_CATALOG_OBJ_ID
const SQ_SUB_MEMBER_MONTHLY_PAN_VAR_ID = process.env.REACT_APP_SQ_SUB_MEMBER_MONTHLY_PAN_VAR_ID
const SQ_SUB_MEMBER_YEARLY_CATALOG_OBJ_ID = process.env.REACT_APP_SQ_SUB_MEMBER_YEARLY_CATALOG_OBJ_ID
const SQ_SUB_MEMBER_YEARLY_PAN_VAR_ID = process.env.REACT_APP_SQ_SUB_MEMBER_YEARLY_PAN_VAR_ID

const SQ_SUB_PRO_MONTHLY_CATALOG_OBJ_ID = process.env.REACT_APP_SQ_SUB_PRO_MONTHLY_CATALOG_OBJ_ID
const SQ_SUB_PRO_MONTHLY_PAN_VAR_ID = process.env.REACT_APP_SQ_SUB_PRO_MONTHLY_PAN_VAR_ID
const SQ_SUB_PRO_YEARLY_CATALOG_OBJ_ID = process.env.REACT_APP_SQ_SUB_PRO_YEARLY_CATALOG_OBJ_ID
const SQ_SUB_PRO_YEARLY_PAN_VAR_ID = process.env.REACT_APP_SQ_SUB_PRO_YEARLY_PAN_VAR_ID

// Pricing
const UNLIMITED_THRESHOLD = 99
const PRICE_PLAN_MEMBER = 12.95
const PRICE_PLAN_PRO = 24.95
const DISCOUNT_PRCT = 0.12
const DISCOUNT_END = '2024-07-30'


const Pricing = () => {
    const {
        token: { colorFillAlter, borderRadius },
    } = theme.useToken();

    const [jtiParameters, setJTIParameters] = useState(null);
    const { isAuthenticated } = useAuth0();
    const { userContext } = useContext(UserContext);

    const [disableMemberSubscription, setDisableMemberSubscription] = useState([]);
    const [disableProSubscription, setDisableProSubscription] = useState([]);

    const [priceModeSwitch, setPriceModeSwitch] = useState(false)
    const [pricePlanMember, setPricePlanMember] = useState(PRICE_PLAN_MEMBER);
    const [pricePlanPro, setPricePlanPro] = useState(PRICE_PLAN_PRO);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const showModal = (content) => {
        setIsModalOpen(true);
        setModalContent(content)
    }
    const handleCancelModal = () => {
        setIsModalOpen(false);
    }

    const featSubscription1 = [
        <span><b>{jtiParameters?.accountTypes.FREE.aiChartView} AI Chart</b> views per month</span>,
        <span><b>{jtiParameters?.accountTypes.FREE.watchlistLimit} Symbols max</b> in your Watchlist</span>,
        <span>Alerts with Trading Profile: <b>DCA</b>, <s><b>Swing Trade</b> or <b>Active Trader</b></s></span>,
        <s>Pro Chart Features<br /><i style={{ fontSize: 'smaller' }}>Bear/Bull trend flags, automatic support and resistance detection, price targets</i></s>,
        'AI Screener',
        'Intelligent Portfolio',
        'Financial Charts',
        'Macro Indexes View',
        'Earnings Calendar',
        <s>AI Earnings summary</s>,
        <s>Financial Graph</s>,
    ]
    const featSubscription2 = [
        <span><b>{jtiParameters?.accountTypes.MEMBER.aiChartView > UNLIMITED_THRESHOLD ? 'Unlimited' : jtiParameters?.accountTypes.MEMBER.aiChartView} AI Chart</b> views</span>,
        <span><b>{jtiParameters?.accountTypes.MEMBER.watchlistLimit} Symbols max</b> in your Watchlist</span>,
        <span>Alerts with Trading Profile: <b>DCA</b>, <b>Swing Trade</b> <s>or <b>Active Trader</b></s></span>,
        <s>Pro Chart Features<br /><i style={{ fontSize: 'smaller' }}>Bear/Bull trend flags, automatic support and resistance detection, price targets</i></s>,
        'AI Screener',
        'Intelligent Portfolio',
        'Financial Charts',
        'Macro Indexes View',
        'Earnings Calendar',
        <s>AI Earnings summary</s>,
        <s>Financial Graph</s>,
    ]
    const featSubscription3 = [
        <span><b>{jtiParameters?.accountTypes.PRO.aiChartView > UNLIMITED_THRESHOLD ? 'Unlimited' : jtiParameters?.accountTypes.PRO.aiChartView} AI Chart</b> views</span>,
        <span><b>{jtiParameters?.accountTypes.PRO.watchlistLimit} symbols</b> in your Watchlist</span>,
        <span>Alerts with Trading Profile <b>DCA</b>, <b>Swing Trade</b> or <b>Active Trader</b></span>,
        <span>Pro Chart Features<br /><i style={{ fontSize: 'smaller' }}>Bear/Bull trend flags, automatic support and resistance detection, price targets</i></span>,
        'AI Screener',
        'Intelligent Portfolio',
        'Financial Charts',
        'Macro Indexes View',
        'Earnings Calendar',
        'AI Earnings summary',
        'Financial Graph',
    ]

    const updPriceMode = (checked) => {
        setPriceModeSwitch(checked)
        setPricePlanMember(checked ? PRICE_PLAN_MEMBER * (1 - DISCOUNT_PRCT) : PRICE_PLAN_MEMBER)
        setPricePlanPro(checked ? PRICE_PLAN_PRO * (1 - DISCOUNT_PRCT) : PRICE_PLAN_PRO)
    }

    const yearPrice = (price) => (price * 12).toFixed(2)


    // EFFECTS //

    useEffect(() => {
        const fetchData = async () => {
            const { data, error } = await getJTIParameters()
            setJTIParameters(data)
        }
        fetchData()

        if (userContext != null) {

            let isTrialMode = userContext.subscriptionEnd !== null && userContext.subscriptionStart === null && userContext.accountType === 'PRO'

            // MARK BIZ : Disable/Enable subscription buttons
            setDisableMemberSubscription((isAuthenticated && userContext.accountType === 'FREE') || isTrialMode ? false : true)
            setDisableProSubscription((isAuthenticated && (userContext.accountType === 'FREE' || userContext.accountType === 'MEMBER')) || isTrialMode ? false : true)
        }
    }, [userContext])


    // RETURNS //

    const CardHeader = ({ title, price, description }) =>
        <div>
            <Title level={2}>{title}</Title>
            <div>
                <span className='price'>${!priceModeSwitch ? price.toFixed(2) : yearPrice(price)}</span>/{!priceModeSwitch ? 'month' : 'year'}
            </div>
            {priceModeSwitch && (
                <Space>
                    <Text className='price-year'>${price.toFixed(2)}/month</Text> <Tag color="grey">You save ${(price * 12 * DISCOUNT_PRCT).toFixed(2)} per year</Tag>
                </Space>
            )}
            <div style={{ marginTop: '1em' }}>
                <Text className='description'>{description}</Text>
            </div>
        </div>

    return (
        <Content id="pricing-layout-content" className="site-layout-content-fixed">
            <MetaTags
                title="Pricing"
                description="Become an informed investor with cutting edge tools at an affordable price"
                keywords="AI trading, AI investing, stock market insights, trading subscription plans, AI stock analysis, JTI pricing, trading alerts, financial charts, 
                intelligent portfolio, AI screener, best trading tools, stock market AI, automated trading insights, pro trading features">
                <script src={REACT_APP_SQ_WEB_SDK} type="text/javascript" />
            </MetaTags>

            <div id="pricing-page-title">
                Take <span style={{ color: COLOR_PRIMARY }}>Control</span> Of Your <span style={{ color: COLOR_PRIMARY }}>Savings</span>
            </div>

            <Row justify='center'>
                <Text className='title-description'>
                    Become an informed investor with cutting edge tools at an affordable price
                </Text>
            </Row>

            {/* LAUNCH OFFER */}
            <Row justify='center'>
                <Text className='try-pro'>
                    Try all Pro features free during 30 days for all new users - Cancel anytime with just one click!
                </Text>
            </Row>

            {/* SWITCH */}
            <Row justify="space-around" align="middle">
                <Space className='switch-content' size='middle'>
                    <Space>Monthly <Switch checked={priceModeSwitch} onChange={updPriceMode} className='pricing-switch' /> Annually</Space>
                    <Tag color={COLOR_PRIMARY} style={{ lineHeight: '1.9em', fontWeight: '500', fontSize: '0.6em' }}>Save {DISCOUNT_PRCT * 100}%</Tag>
                </Space>
            </Row>

            <Row gutter={GRID_GUTTER} justify="space-around" align="top">
                <Col lg={{ span: 8 }}>
                    <Card className='card card-content' hoverable title={
                        <CardHeader title='JTI Free' price={0} description='Get AI-powered market insights, trend analysis, and essential financial tools—free forever!' />
                    }>
                        <div className='card-button'>
                            <SignupButton type='primary' size='large' label='Start Now' disabled={isAuthenticated ? true : false} />
                        </div>

                        <List split={false} dataSource={featSubscription1} renderItem={(item) => (
                            <List.Item>
                                <Space size='large'><CheckOutlined className='feature-icon' />{item}</Space>
                            </List.Item>
                        )} />
                    </Card>
                </Col>
                <Col lg={{ span: 8 }}>
                    <Card className='card card-content' hoverable title={
                        <CardHeader title='JTI Member' price={pricePlanMember} description='Unlock unlimited AI chart views, smart alerts, and pro-level market insights for informed trading decisions.' />
                    }>
                        <div className='card-button'>
                            {isAuthenticated ? (
                                <Button type="primary" size='large' disabled={PAYMENT_DISABLE || disableMemberSubscription}
                                    onClick={() => showModal(
                                        <SubscriptionForm
                                            item='JTI Member Plan'
                                            itemDesc={!priceModeSwitch ? '$' + pricePlanMember.toFixed(2) + ' Billed monthly' : '$' + pricePlanMember.toFixed(2) + ' * 12 Billed yearly'}
                                            price={(!priceModeSwitch ? pricePlanMember : pricePlanMember * 12)}
                                            subscriptionPlan='MEMBER'
                                            catalogObjId={(!priceModeSwitch ? SQ_SUB_MEMBER_MONTHLY_CATALOG_OBJ_ID : SQ_SUB_MEMBER_YEARLY_CATALOG_OBJ_ID)}
                                            planVarId={(!priceModeSwitch ? SQ_SUB_MEMBER_MONTHLY_PAN_VAR_ID : SQ_SUB_MEMBER_YEARLY_PAN_VAR_ID)} />)}>
                                    Upgrade
                                </Button>
                            ) : (
                                <SignupButton type='primary' size='large' label='Start Now' />
                            )}
                        </div>

                        <List split={false} dataSource={featSubscription2} renderItem={(item) => (
                            <List.Item>
                                <Space size='large'><CheckOutlined className='feature-icon' />{item}</Space>
                            </List.Item>
                        )} />
                    </Card>
                </Col>
                <Col lg={{ span: 8 }}>
                    <Badge.Ribbon text="Most Popular" style={{ marginTop: '1em' }}>
                        <Card className='card card-content default-selected-card' hoverable title={
                            <CardHeader title='JTI Pro' price={pricePlanPro} description='Gain full market visibility with advanced AI tools, 100 watchlist symbols, and pro-level trading intelligence.' />
                        }>
                            <div className='card-button'>
                                {isAuthenticated ? (
                                    <Button type="primary" size='large' disabled={PAYMENT_DISABLE || disableProSubscription}
                                        onClick={() => showModal(
                                            <SubscriptionForm
                                                item='JTI Pro Plan'
                                                itemDesc={!priceModeSwitch ? '$' + pricePlanPro.toFixed(2) + ' Billed monthly' : '$' + pricePlanPro.toFixed(2) + ' * 12 Billed yearly'}
                                                price={(!priceModeSwitch ? pricePlanPro : pricePlanPro * 12)}
                                                subscriptionPlan='PRO'
                                                catalogObjId={(!priceModeSwitch ? SQ_SUB_PRO_MONTHLY_CATALOG_OBJ_ID : SQ_SUB_PRO_YEARLY_CATALOG_OBJ_ID)}
                                                planVarId={(!priceModeSwitch ? SQ_SUB_PRO_MONTHLY_PAN_VAR_ID : SQ_SUB_PRO_YEARLY_PAN_VAR_ID)} />)}>
                                        Upgrade
                                    </Button>
                                ) : (
                                    <SignupButton type='primary' size='large' label='Start Now' />
                                )}
                            </div>

                            <List split={false} dataSource={featSubscription3} renderItem={(item) => (
                                <List.Item>
                                    <Space size='large'><CheckOutlined className='feature-icon' />{item}</Space>
                                </List.Item>
                            )} />
                        </Card>
                    </Badge.Ribbon>
                </Col>
            </Row>

            <Text italic className='pricing-legend'>* All plans can be canceled anytime - The launch offer period can be changed anytime</Text>

            <Modal title="Order Summary" open={isModalOpen} onCancel={handleCancelModal} destroyOnClose={true} footer={null}>
                {modalContent}
            </Modal>
        </Content>
    )
}

export default Pricing;