import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/Blog.css';
import CommonStocksVSPreferredStocks from './blog/CommonStocksVSPreferredStocks';
import DiversifiedStockPortfolio from './blog/DiversifiedStockPortfolio';
import IndexFunds from './blog/IndexFunds';
import PriceToBookRatio from './blog/PriceToBookRatio';
import PriceToEarningsRatio from './blog/PriceToEarningsRatio';
import RatiosTerms from './blog/RatiosTerms';
import StockMarketInvesting from './blog/StockMarketInvesting';
import SwingResilientStrategy from './blog/SwingResilientStrategy';
import TechnicalAnalysisStockMarket from './blog/TechnicalAnalysisStockMarket';
import TheBasicsofDividendInvesting from './blog/TheBasicsofDividendInvesting';
import Top10GrowthStocks2025 from './blog/Top10GrowthStocks2025';
import TopInvestingBooks from './blog/TopInvestingBooks';
import TradeChecklist from './blog/TradingChecklist';
import Error from './Error';
import { blogPosts } from './Blog';


const components = {
    [blogPosts[0].path]: <SwingResilientStrategy title={blogPosts[0].title} imgFile={blogPosts[0].imgFile} updatedDate={blogPosts[0].updatedDate} />,
    [blogPosts[1].path]: <TradeChecklist title={blogPosts[1].title} imgFile={blogPosts[1].imgFile} updatedDate={blogPosts[1].updatedDate} />,
    [blogPosts[2].path]: <CommonStocksVSPreferredStocks title={blogPosts[2].title} imgFile={blogPosts[2].imgFile} updatedDate={blogPosts[2].updatedDate} />,
    [blogPosts[3].path]: <TheBasicsofDividendInvesting title={blogPosts[3].title} imgFile={blogPosts[3].imgFile} updatedDate={blogPosts[3].updatedDate} />,
    [blogPosts[4].path]: <Top10GrowthStocks2025 title={blogPosts[4].title} imgFile={blogPosts[4].imgFile} updatedDate={blogPosts[4].updatedDate} />,
    [blogPosts[5].path]: <StockMarketInvesting title={blogPosts[5].title} imgFile={blogPosts[5].imgFile} updatedDate={blogPosts[5].updatedDate} />,
    [blogPosts[6].path]: <RatiosTerms title={blogPosts[6].title} imgFile={blogPosts[6].imgFile} updatedDate={blogPosts[6].updatedDate} />,
    [blogPosts[7].path]: <PriceToEarningsRatio title={blogPosts[7].title} imgFile={blogPosts[7].imgFile} updatedDate={blogPosts[7].updatedDate} />,
    [blogPosts[8].path]: <IndexFunds title={blogPosts[8].title} imgFile={blogPosts[8].imgFile} updatedDate={blogPosts[8].updatedDate} />,
    [blogPosts[9].path]: <DiversifiedStockPortfolio title={blogPosts[9].title} imgFile={blogPosts[9].imgFile} updatedDate={blogPosts[9].updatedDate} />,
    [blogPosts[10].path]: <TopInvestingBooks title={blogPosts[10].title} imgFile={blogPosts[10].imgFile} updatedDate={blogPosts[10].updatedDate} />,
    [blogPosts[11].path]: <TechnicalAnalysisStockMarket title={blogPosts[11].title} imgFile={blogPosts[11].imgFile} updatedDate={blogPosts[11].updatedDate} />,
    [blogPosts[12].path]: <PriceToBookRatio title={blogPosts[12].title} imgFile={blogPosts[12].imgFile} updatedDate={blogPosts[12].updatedDate} />,
}


const BlogPost = () => {

    const { urlPath } = useParams();

    return components[urlPath] ? components[urlPath] : <Error />;
};

export default BlogPost;