import { animated, useChain, useSpringRef, useTrail } from '@react-spring/web';
import { Avatar, Card, Carousel, Col, Image, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SignupButton } from "../../../components/buttons/signup-button";
import { COLOR_GREEN, COLOR_PRIMARY, COLOR_RED } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/Arrival2.module.css';
import { isMobile } from '../../../utils/utils';

const { Text, Paragraph } = Typography
const IS_MOBILE = isMobile()
const { Meta } = Card


// SHARED VARIABLES //

const signUpButtonText = "Start Free Today"


const Arrival1 = () => {

    // SPRING EFFECTS //

    const springRefTrailHomeTitle = useSpringRef()
    const trailHomeTitle = useTrail(3, {
        ref: springRefTrailHomeTitle,
        config: { mass: 5, tension: 1800, friction: 200 },
        opacity: 1,
        y: 0,
        from: { opacity: 0, y: -20, },
    })

    const springRefTrailDots = useSpringRef()
    const trailDots = useTrail(2, {
        ref: springRefTrailDots,
        config: { mass: 5, tension: 1800, friction: 200 },
        opacity: 1,
        y: 0,
        from: { opacity: 0, y: -20, },
    })

    const springRefTrailAssets = useSpringRef()
    const trailAssets = useTrail(3, {
        ref: springRefTrailAssets,
        config: { mass: 5, tension: 1800, friction: 200 },
        opacity: 1,
        y: 0,
        from: { opacity: 0, y: -20, },
    })

    useChain([springRefTrailHomeTitle, springRefTrailDots, springRefTrailAssets])

    // CAROUSEL //

    const navigate = useNavigate();
    const routeToDemo = () => {
        navigate('/demo')
    }

    const winStyle = { color: COLOR_RED }
    const roiStyle = { color: COLOR_GREEN }
    const carouselData = [
        {
            title: "Tesla",
            extra: "https://companiesmarketcap.com/img/company-logos/64/TSLA.webp",
            content: <div><span className={styles['card-metric']} style={winStyle}>88%</span> Success <br /><span className={styles['card-metric']} style={roiStyle}>+37%</span> Average Return</div>
        },
        {
            title: "Microsoft",
            extra: "https://companiesmarketcap.com/img/company-logos/64/MSFT.webp",
            content: <div><span className={styles['card-metric']} style={winStyle}>91%</span> Success <br /><span className={styles['card-metric']} style={roiStyle}>+20%</span> Average Return</div>
        },
        {
            title: "Apple",
            extra: "https://companiesmarketcap.com/img/company-logos/64/AAPL.webp",
            content: <div><span className={styles['card-metric']} style={winStyle}>71%</span> Success <br /><span className={styles['card-metric']} style={roiStyle}>+15%</span> Average Return</div>
        },
        {
            title: "Coca-Cola",
            extra: "https://companiesmarketcap.com/img/company-logos/64/KO.webp",
            content: <div><span className={styles['card-metric']} style={winStyle}>79%</span> Success <br /><span className={styles['card-metric']} style={roiStyle}>+7%</span> Average Return</div>
        },
    ]

    // MOBILE
    var chunkSize = 2
    if (IS_MOBILE)
        chunkSize = 1

    // Group by chuncks
    const chunks = []
    for (let i = 0; i < carouselData.length; i += chunkSize) {
        chunks.push(carouselData.slice(i, i + chunkSize));
    }

    const carousel =
        <Carousel className={styles['carousel']} autoplay effect="fade" dots={false} autoplaySpeed={2500}>
            {chunks.map((chunk, idx1) => (
                <div key={idx1}>
                    <Row gutter={30}>
                        {chunk.map((item, idx2) => (
                            <Col key={idx2} xs={24} sm={24} md={24} lg={12}>
                                <Card size='small' className='card' style={{ margin: '6px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }} onClick={routeToDemo}>
                                    <Meta className={styles['card-meta']}
                                        avatar={<Avatar src={item.extra} />}
                                        title={<span className={styles['card-meta']}>{item.title}</span>}
                                        description={item.content}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </Carousel>

    const signUpButton =
        <div>
            <SignupButton className={styles['signup']} type='primary' label={signUpButtonText} />
        </div>

    const assetsList =
        <div className={styles['assets-list']}>
            <Space size='large'>
                <animated.span style={trailAssets[0]}>STOCK</animated.span>
                <animated.span style={trailDots[0]}>•</animated.span>
                <animated.span style={trailAssets[1]}>ETF</animated.span>
                <animated.span style={trailDots[0]}>•</animated.span>
                <animated.span style={trailAssets[2]}>CRYPTO</animated.span>
            </Space>
        </div>

    return (
        <div className={styles['container']}>
            <div className={styles['content']}>
                <Row gutter={{ sm: 0, md: 50 }} justify='center'>
                    <Col sm={24} md={11} className={styles['block-left']}>
                        <div className={styles['block-left-content']}>
                            <div className={styles['title']}>
                                <animated.div style={trailHomeTitle[0]}>Become <span style={{ color: COLOR_PRIMARY }}>Investor</span></animated.div>
                                <animated.div style={trailHomeTitle[1]}>
                                    In <span style={{ color: COLOR_PRIMARY }}>No Time</span> with <animated.span style={{ ...trailHomeTitle[1], fontSize: '0.95em', border: '2px solid ' + COLOR_PRIMARY, color: COLOR_PRIMARY, padding: '0px 11px' }}>AI</animated.span>
                                </animated.div>
                            </div>

                            <div style={{ width: '7rem', borderBottom: '1px solid #bfbfbf', margin: IS_MOBILE && 'auto' }} />
                            <Paragraph className={styles['page-description']}>
                                Our refined dashboard guides you to the perfect momentum to enter or exit a position — never trade alone again!
                            </Paragraph>

                            {!IS_MOBILE && signUpButton}
                            {!IS_MOBILE && assetsList}

                        </div>

                        {/* Caroussel */}
                        {!IS_MOBILE && carousel}
                    </Col>
                    <Col sm={24} md={13}>
                        {/* ~1950xfull */}
                        <Image id={styles['candle']} src='/landing_laptop.png' alt='Candlestick Chart AI Signals' preview={false} />
                        {IS_MOBILE && assetsList}
                        {IS_MOBILE && carousel}
                        {IS_MOBILE && signUpButton}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Arrival1;