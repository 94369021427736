import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Card, Carousel, Col, Rate, Row } from 'antd';
import { useInView } from 'react-intersection-observer';
import { Tweet } from 'react-tweet';
import { COLOR_PRIMARY, GRID_GUTTER } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/Testimonial.module.css';
import { isMobile } from '../../../utils/utils';
import { IN_VIEW, IN_VIEW_MOBILE } from '../../Home';


// SHARED VARIABLES //
const IS_MOBILE = isMobile()


const Testimonial = () => {


    // OBSERVER & SPRING EFFECTS //

    const [refObserver, inView] = useInView({
        triggerOnce: true, // Only trigger once when entering viewport

        // Percentage of the HTML element visibility to trigger the animation
        threshold: IS_MOBILE ? IN_VIEW_MOBILE : IN_VIEW,
    })

    const springRefTestimonialsTitle = useSpringRef()
    const springTestimonialsTitle = useSpring({
        ref: springRefTestimonialsTitle,
        config: { duration: 600 },
        from: { opacity: 0, },
        to: {
            opacity: inView ? 1 : 0,
        },
    })

    const springRefTestimonials = useSpringRef()
    const springTestimonials = useSpring({
        ref: springRefTestimonials,
        config: { duration: 1000 },
        from: { opacity: inView ? 0 : 1, },
        to: {
            opacity: inView ? 1 : 0
        },
    })

    useChain([springRefTestimonialsTitle, springRefTestimonials], [0, 1], IS_MOBILE ? 1000 : 600)


    // CAROUSEL //

    const carouselData = [
        {
            tweetId: '1849821172721938617',
        },
        {
            author: 'Fabien - Software Engineer',
            text: 'I’ve been using your algorithm for a little over a year now. I just reviewed my investments over the past year, and I made +50.8% 🙂 So, just a huge, huge thank you! I’m having a lot of fun investing by following JTI’s advice!',
            rate: 5,
        },
        {
            tweetId: '1855413779703419200',
        },
        {
            author: 'Yannick - Software Architect',
            text: 'Justtradeit is a very good website for those who want to trade even without having a lot of knowledge on the subject. It is suitable for all levels with a very good interface. For my part, I started not very long ago and I reached up to 1 or 2% per month without really understanding what I was doing. Since I use justtradeit I have really increased this ratio. The site provides a lot of data that helps users make the right decisions!',
            rate: 5,
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
    ]

    // MOBILE
    var chunkSize = 6
    if (IS_MOBILE)
        chunkSize = 1

    // Group by chuncks
    const chunks = []
    for (let i = 0; i < carouselData.length; i += chunkSize) {
        chunks.push(carouselData.slice(i, i + chunkSize));
    }

    const carousel =
        <Carousel className={styles['carousel']} autoplay={{ dotDuration: true }} autoplaySpeed={5000} dots={{
            className: 'carousel-dots'
        }}>
            {chunks.map((chunk, idx1) => (
                <div key={idx1}>
                    <Row gutter={[50, 0]} justify="space-around" align="middle">
                        {chunk.map((item, idx2) => (
                            <Col key={idx2} xs={24} sm={24} md={24} lg={8}>
                                <div className={styles['tweet']} data-theme="light">
                                    {
                                        item.tweetId ?
                                            <Tweet id={item.tweetId} />
                                            :
                                            <Card key={idx2} className="card" style={{ textAlign: "center" }}>
                                                <div className={styles['testimonial-author']}>
                                                    {item.author}
                                                </div>
                                                <div className={styles['testimonial-text']}>
                                                    "{item.text}"
                                                </div>
                                                <div className={styles['testimonial-rate']}>
                                                    <Rate value={item.rate} />
                                                </div>
                                            </Card>
                                    }
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </Carousel>


    return (
        <div className="page" ref={refObserver}>
            <div className="landing-content">
                <animated.div className='enrollement-title' style={springTestimonialsTitle}>
                    They <span style={{ color: COLOR_PRIMARY }}>Invest</span> And Make <span style={{ color: COLOR_PRIMARY }}>Positive Return</span>
                </animated.div>
                <animated.div style={springTestimonials}>
                    {carousel}
                </animated.div>
            </div>
        </div>
    )
}

export default Testimonial;