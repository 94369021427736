import { Typography } from 'antd';
import React from 'react';
import PageTitle1 from '../../components/PageTitle1';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import MyBreadcrumb from '../../components/Breadcrumb';
import { FAQ_CRUMB } from '../FAQ';


const { Paragraph } = Typography;

const title = "How does Just Trade It benefit users?"

const JustTradeItBenefits = ({ onlyContent = false }) => {

    const content = <>
        <Paragraph className='blog-paragraph'>
            Just Trade It simplifies trading by providing AI-driven insights, interactive tools, and actionable strategies. It helps traders understand market trends, identify optimal entry and exit points, and make data-driven decisions to maximize their trading outcomes. By saving time and eliminating the need for specific trading knowledge, such as understanding complex statistical formulas, Just Trade It makes trading accessible to everyone.
        </Paragraph>
    </>

    if (onlyContent)
        return content

    return (<>
        <MetaTags
            title={title}
            description="Explore the benefits of Just Trade It, a platform that simplifies trading with AI-driven insights, interactive tools, and actionable strategies."
            keywords="AI trading benefits, Just Trade It tools, smarter trading decisions"
        />

        <MyBreadcrumb items={FAQ_CRUMB.concat([
            {
                title: title,
            }
        ])} />

        <PageTitle1> • {title}</PageTitle1>

        {content}
    </>)
}

export default JustTradeItBenefits;