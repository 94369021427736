import { RobotOutlined } from '@ant-design/icons';
import { Card, Descriptions, Divider, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { displayData, getLogoUrl, isMobile } from '../utils/utils';
import { IconInfoActiveBuySignal, IconInfoActiveSellSignal, IconInfoAVGROI, IconInfoMedianSwing, IconInfoWinLose, IconLoading, IconSignalBuyL1, IconSignalBuyL2, IconSignalOff, IconSignalSellL1, IconSignalSellL2 } from './Icons';
import TickerInfoDescCrypto from './TickerInfoDescCrypto';
import TickerInfoDescETF from './TickerInfoDescETF';
import TickerInfoDescStock from './TickerInfoDescStock';
import ModalBasic from './modals/ModalBasic';
import Paragraph from 'antd/es/typography/Paragraph';
import { ExplainAIEfficiency } from './TempLang';

const { Item } = Descriptions;


// PARAMETERS //

const styleItalic = { fontSize: 'smaller' }


const TickerInfo = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    // Let Tour run after the component is loaded, otherwise tour won't work
    useEffect(() => {
        props.setTickerInfoIsLoaded(true)
    })

    if (props.tkrInfos === null || props.isLoading) {
        return (
            <div style={{ height: '20em', alignItems: 'center', display: 'flex' }}>
                <IconLoading />
            </div>)
    }

    const tkrInfos = props.tkrInfos
    // console.log(JSON.stringify(tkrInfos))

    // Define interval
    var efficiency = tkrInfos.oneDay
    if (props.interval == 'hourly') {
        efficiency = tkrInfos.oneHour
    }

    const winRate = efficiency.total_win_percent.toFixed(2) + '%'
    const avgROI = efficiency.roi_avg_all.toFixed(2) + '%'

    var descriptions = ''
    switch (tkrInfos.assetType) {
        case 'STOCK':
            descriptions = <TickerInfoDescStock tkrInfos={tkrInfos} efficiency={efficiency} />
            break;
        case 'ETF':
            descriptions = <TickerInfoDescETF tkrInfos={tkrInfos} efficiency={efficiency} />
            break;
        case 'CRYPTO':
            descriptions = <TickerInfoDescCrypto tkrInfos={tkrInfos} efficiency={efficiency} />
            break;
    }


    return (<>
        <Card className='card grid-bottom'>
            <div ref={props.tourRefs[2]}>
                {descriptions}
            </div>

            <Divider orientation="left" className='card-divider'>AI Model Efficiency</Divider>

            <div ref={props.tourRefs[3]}>
                <Descriptions column={{ xs: 1, sm: 1, md: 3, lg: 3 }} size={isMobile() ? 'small' : 'default'}>
                    <Item label={<>Win Rate&nbsp;<i style={styleItalic}>(Win/Loss)</i><IconInfoWinLose /></>}>
                        <span>
                            <b>{winRate}</b> <i style={styleItalic}>({efficiency.total_win_trade}/{efficiency.total_lose_trade})</i>
                        </span>
                    </Item>
                    <Item label={<>Avg ROI for Each Trade<IconInfoAVGROI /></>}>
                        <b>{avgROI}</b>
                    </Item>
                    <Item label={<>Median Swing Time<IconInfoMedianSwing /></>}>
                        <b>{displayData(efficiency.swingTimeMedian?.toFixed(1))} days</b>
                    </Item>
                    <Item label={<>Active Buy Signal<IconInfoActiveBuySignal /></>}>
                        &nbsp;&nbsp;
                        <Space size="middle">
                            <span>{(efficiency.activeSignalBuyL1 ? <IconSignalBuyL1 /> : <IconSignalOff />)}L1</span>
                            <span>{(efficiency.activeSignalBuyL2 ? <IconSignalBuyL2 /> : <IconSignalOff />)}L2</span>
                        </Space>
                    </Item>
                    <Item label={<>Active Sell Signal<IconInfoActiveSellSignal /></>}>
                        &nbsp;&nbsp;
                        <Space size="middle">
                            <span>{(efficiency.activeSignalSellL1 ? <IconSignalSellL1 /> : <IconSignalOff />)}L1</span>
                            <span>{(efficiency.activeSignalSellL2 ? <IconSignalSellL2 /> : <IconSignalOff />)}L2</span>
                        </Space>
                    </Item>
                    <Item>
                        <Space size="small" style={{ cursor: 'pointer' }} onClick={() => setIsModalVisible(true)}>
                            <i>Explain this metrics?</i><RobotOutlined />
                        </Space>
                    </Item>
                </Descriptions>
            </div>
        </Card>

        <ModalBasic open={isModalVisible} setOpen={setIsModalVisible} icon={getLogoUrl(tkrInfos.ticker)}
            title={tkrInfos.shortName}>
            <Paragraph className="modal-paragraph reading">
                {ExplainAIEfficiency(winRate, avgROI)}
            </Paragraph>
        </ModalBasic>
    </>)
}

export default TickerInfo;