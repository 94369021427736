import '../styles/Home.css';

import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Layout } from 'antd';
import { useInView } from 'react-intersection-observer';
import { SignupButton } from "../components/buttons/signup-button";
import { isMobile } from '../utils/utils';
import Arrival2 from './landings/pages/Arrival2';
import Candlestick from './landings/pages/Candlestick';
import Enrollement1 from './landings/pages/Enrollement1';
import FinancialCharts from './landings/pages/FinancialCharts';
import Portfolio from './landings/pages/Portfolio';
import Screener from './landings/pages/Screener';
import FAQ from './landings/pages/FAQ';
import Testimonial from './landings/pages/Testimonial';


// SHARED VARIABLES //
const IS_MOBILE = isMobile()

// lg is the breack point for iPad Pro 11 landscape
export const gutter = { xs: 0, sm: 0, md: 30, lg: 80, xxl: 90 }
export const IN_VIEW = 0.50
export const IN_VIEW_MOBILE = 0.5


const Home = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refP4, inViewP4] = useInView({
        triggerOnce: true,
        threshold: IS_MOBILE ? 0.3 : 0.54,
    })

    const springRefLove = useSpringRef()
    const springLove = useSpring({
        ref: springRefLove,
        config: { duration: 1000 },
        from: { opacity: inViewP4 ? 0 : 1, },
        to: {
            opacity: inViewP4 ? 1 : 0,
        },
    })
    const springRefGetStarted = useSpringRef()
    const springGetStarted = useSpring({
        ref: springRefGetStarted,
        config: { duration: 900 },
        from: {
            opacity: inViewP4 ? 0 : 1,
            transform: 'translateX(-30)',
        },
        to: {
            opacity: inViewP4 ? 1 : 0,
            transform: inViewP4 ? 'translateX(0)' : 'translateX(-30px)'
        },
    })
    useChain([springRefLove, springRefGetStarted])


    return (
        <Layout>

            <Arrival2 />
            <Enrollement1 />
            <Candlestick />
            <Screener />
            <Portfolio />
            <FinancialCharts />
            {/* <Testimonial /> */}
            <FAQ />

            <div className="page4" ref={refP4}>
                <div className="landing-content">
                    <animated.div className="value-title" style={{ ...springLove, }}>
                        Trade Smarter, Not Harder...
                    </animated.div>
                    <animated.div style={{ ...springGetStarted, }}>
                        <SignupButton className="value-signup" label='Get Started Now' />
                    </animated.div>
                </div>
            </div>
        </Layout>
    )
}

export default Home;