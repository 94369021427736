import { InfoCircleOutlined } from '@ant-design/icons';
import { Typography, notification, } from 'antd';
import React, { useContext, useEffect } from "react";
import UserContext from './UserContext';
import { Link } from "react-router-dom";



const NewsNotifications = () => {

    const { userContext, updateUserContext } = useContext(UserContext);

    // Notifications
    const [api, contextHolder] = notification.useNotification();
    const placement = 'topRight'

    const notifs = [
        {
            id: "20231025_release_v15",
            notifData: {
                message: 'New Features',
                description:
                    <>
                        Make sure to follow first principle before to buy shares using the new <Link to="/blog/tradechecklist">Trade Checklist</Link> page.
                        For more informations, please refer to the <Link to="/jti-ai-model">AI Analysis</Link> documentation.
                    </>,
                placement,
                duration: 300,
                icon: (<InfoCircleOutlined style={{ color: 'orange', }} />),
            }
        },
        {
            id: "20231031_release_v16",
            notifData: {
                message: 'New Features',
                description:
                    <>
                        Get notified of new signals for symbols in your <Link to="/watchlist">Watchlist</Link>.
                    </>,
                placement,
                duration: 300,
                icon: (<InfoCircleOutlined style={{ color: 'orange', }} />),
            }
        }
    ]

    const openNotification = (id) => {
        const notif = notifs.find((n) => n.id === id)
        api.info(notif.notifData)
    }

    useEffect(() => {

        if (userContext != null && userContext.notifications != null) {

            // MARK : Javascript Map Iteration
            for (const [id, show] of Object.entries(userContext.notifications)) {
                if (show) {
                    // Disable notif for the user
                    userContext.notifications[id] = false
                    updateUserContext(userContext)
                    openNotification(id)
                }
            }
        }

    })

    return (
        <>
            {contextHolder}
        </>
    )

}

export default NewsNotifications;