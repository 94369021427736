import { Card, Col, Divider, Layout, Row, Space, Typography } from 'antd';
import { IconBearFlag, IconBullFlag, IconEarning, IconEMA, IconMA, IconSignalBuyL1, IconSignalBuyL2, IconSignalOff, IconSignalSellL1, IconSignalSellL2, IconVolume } from '../components/Icons';
import { Disclaimer, InfoAIEfficiency, InfoBuySignal, InfoDivYield, InfoExDivDate, InfoSellSignal, InfoShortRatio, InfoTagFinancialStrong, InfoTagFinancialWeak, InfoTagResilient, InfoTagYoung, TakeProfitDesc } from '../components/TempLang';

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import PageTitle1 from '../components/PageTitle1';
import { TagAIAverage, TagAIGood, TagAIHigh, TagAILow, TagClassResilient, TagClassYoung, TagFinStrong, TagFinWeak } from '../components/Tags';
import MetaTags from '../MetaTags';
import { getTickerList } from "../services/ticker.service";

const { Title, Paragraph } = Typography;


const JTIAIModel = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [tickerList, setTickerList] = useState("");
    const [nbTkr, setNbTkr] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const formattedStocks = [];
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getTickerList(accessToken);

            for (const symbol in data) {
                const companyName = data[symbol];
                const formattedEntry = `${companyName} (${symbol})`;
                formattedStocks.push(formattedEntry);
            }

            setNbTkr(formattedStocks.length)
            setTickerList(formattedStocks.join(", "))
        }
        fetchData();
    }, [])

    const rowStyle = { padding: '10px 10px' }
    const rowGutt = [4, 4]
    const titleSize = 3
    const col1Span = { span: 3 }
    const col2Span = { span: 21 }

    return (
        <Layout className="site-layout-content-fixed">
            <MetaTags
                title="Just Trade It: AI Model Reference" />

            <PageTitle1> • JTI AI Analysis</PageTitle1>

            <br />
            <Paragraph>
                Welcome to Just Trade It!<br /><br />
                Although the <b>JTI AI Model</b> is performing plenty calculations for you, we also encourage users to acquire <b>basic knowledge of technical chart analysis</b>.
                This will help them to better understand how the AI model works. Below are some informations regarding the AI Model.
            </Paragraph>

            <Title level={titleSize}>Our AI Model</Title>
            <Paragraph>
                JTI AI Model correlate multiple parameters by analysing assets behavior's from it's firt day, as well as macro and environment indicators. The parameters include market trends, volatility, supports and resistances among others.
                It’s important to note that our model is not predicting the future movement of the asset, but rather the best moment to make gains, according to the history data.
                Furthermore, market analysis is not an exact science, and predicting the market is like predicting the future. Our model reflects our way of approaching the market in the most secure manner.
            </Paragraph>

            <Title level={titleSize}>Tags, Icons And Lexicon</Title>
            <Paragraph>
                <Card title={<><span className='ai-logo'>AI</span> Tags And Icons</>}>
                    <Row gutter={rowGutt} style={rowStyle} justify='center'>
                        <Col align='middle' lg={col1Span}>
                            <TagClassResilient />
                        </Col>
                        <Col lg={col2Span}>
                            <InfoTagResilient />
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle} justify='center'>
                        <Col align='middle' lg={col1Span}>
                            <TagClassYoung />
                        </Col>
                        <Col lg={col2Span}>
                            <InfoTagYoung />
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle} justify='center'>
                        <Col align='middle' lg={col1Span}>
                            <TagFinStrong />
                        </Col>
                        <Col lg={col2Span}>
                            <InfoTagFinancialStrong />
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle} justify='center'>
                        <Col align='middle' lg={col1Span}>
                            <TagFinWeak />
                        </Col>
                        <Col lg={col2Span}>
                            <InfoTagFinancialWeak />
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle} justify='center'>
                        <Col align='middle' lg={col1Span}>
                            <Space direction='vertical'>
                                <TagAIHigh />
                                <TagAIGood />
                                <TagAIAverage />
                                <TagAILow />
                            </Space>
                        </Col>
                        <Col lg={col2Span}>
                            <InfoAIEfficiency />
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}>
                            <Space size="middle"><span><IconSignalOff /> L1</span><span><IconSignalOff /> L2</span></Space>
                        </Col>
                        <Col lg={col2Span}>
                            No active signals.
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}>
                            <Space size="middle"><span><IconSignalBuyL1 /> L1</span><span><IconSignalBuyL2 /> L2</span></Space>
                        </Col>
                        <Col lg={col2Span}>
                            <InfoBuySignal />
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}>
                            <Space size="middle"><span><IconSignalSellL1 /> L1</span><span><IconSignalSellL2 /> L2</span></Space>
                        </Col>
                        <Col lg={col2Span}>
                            <InfoSellSignal />
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}>
                            <Space size="middle"><span><IconEMA /> L1</span><span><IconMA /> L2</span></Space>
                        </Col>
                        <Col lg={col2Span}>
                            Take Profit Level 1 <b>(L1)</b> or Level 2 <b>(L2)</b>. {TakeProfitDesc}
                        </Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}><IconVolume /></Col>
                        <Col lg={col2Span}>Indicate the volume of shares sold per day</Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}><IconEarning /></Col>
                        <Col lg={col2Span}>Indicates the earning date for a company; companies will release their earning data on this date.</Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}><IconBullFlag /></Col>
                        <Col lg={col2Span}>Flag indicating that the asset is entering a bull market cycle.</Col>
                    </Row>
                    <Row gutter={rowGutt} style={rowStyle}>
                        <Col align='middle' lg={col1Span}><IconBearFlag /></Col>
                        <Col lg={col2Span}>Flag indicating that the asset is entering a bear market cycle.</Col>
                    </Row>
                </Card>
            </Paragraph>
            <Paragraph>
                <Title level={5}>Common Financial Lexicon</Title>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col lg={col1Span}><b>Dividends Yield</b></Col>
                    <Col lg={col2Span}><InfoDivYield /></Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col lg={col1Span}><b>Ex-Dividend Date</b></Col>
                    <Col lg={col2Span}><InfoExDivDate /></Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col lg={col1Span}><b>Short Ratio</b></Col>
                    <Col lg={col2Span}><InfoShortRatio /></Col>
                </Row>
            </Paragraph>

            <Title level={titleSize}>Stock Symbol List</Title>
            <Paragraph>
                Here are all the {nbTkr} symbols analyzed with our AI model:
            </Paragraph>
            <Paragraph>
                {tickerList}, more is coming...
            </Paragraph>

            <br />
            <Divider />
            <Title level={5} style={{ marginTop: '0' }}><i>Disclaimer</i></Title>
            <Paragraph><Disclaimer /></Paragraph>
        </Layout >
    )
}

export default JTIAIModel;