import { AimOutlined, MailOutlined } from '@ant-design/icons';
import { animated, useChain, useSpring, useSpringRef, useTrail } from '@react-spring/web';
import { Col, Radio, Row, Space, Typography } from 'antd';
import { useInView } from 'react-intersection-observer';
import { IconSignalBuyL2, IconSignalSellL2 } from '../../../components/Icons';
import { TagAITagging } from '../../../components/Tags';
import { COLOR_PRIMARY, tradingProfileOptions } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/Enrollement1.module.css';
import { isMobile } from '../../../utils/utils';


// SHARED VARIABLES //
const { Title, Text } = Typography
const IS_MOBILE = isMobile()


const Enrollement1 = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refObserver, inView] = useInView({
        triggerOnce: true,
        threshold: IS_MOBILE ? 0.15 : 0.4,
    })

    const springRefEnrollementTitle = useSpringRef()
    const springEnrollementTitle = useSpring({
        ref: springRefEnrollementTitle,
        config: { duration: 600 },
        from: { opacity: inView ? 0 : 0, },
        to: {
            opacity: inView ? 1 : 0,
        },
    })

    const springRefEnrollement = useSpringRef()
    const trailEnrollement = useTrail(tradingProfileOptions.length, {
        ref: springRefEnrollement,
        config: { duration: 400 },
        from: {
            opacity: inView ? 0 : 1,
        },
        to: {
            opacity: inView ? 1 : 0,
        },
    });
    useChain([springRefEnrollementTitle, springRefEnrollement], [0, 1], IS_MOBILE ? 1000 : 600)


    return (
        <div className="page" ref={refObserver}>
            <div className="landing-content">
                <Row justify="space-around">
                    <animated.div className='enrollement-title' style={springEnrollementTitle}>Effortless Strategy <span style={{ color: COLOR_PRIMARY }}>In Few Steps</span></animated.div>
                </Row>
                <Row gutter={{ xs: 0, sm: 90 }} className={styles['items-container']}>
                    <Col xs={24} md={8}>
                        <animated.div style={trailEnrollement[0]}>
                            <div className='title-number'>1.</div>
                            <Title level={2} className='page-title enrollement-item-title'>
                                Select your Trading Profile
                            </Title>
                            <Text className='page-description'>
                                Choose a trading strategy that aligns with your lifestyle, based on the time and effort you’re able to commit: <b>Dollar Cost Averaging (DCA), Swing Trader, Active Trader</b>.
                            </Text>
                        </animated.div>
                    </Col>
                    <Col xs={24} md={8}>
                        <animated.div style={trailEnrollement[1]}>
                            <div className='title-number'>2.</div>
                            <Title level={2} className='page-title'>
                                Find the Best Opportunities
                            </Title>
                            <Text className='page-description'>
                                Use the <b>Screener</b> to find the best market opportunities based on our <b>AI Signals</b> &nbsp;<IconSignalBuyL2 /> <IconSignalSellL2 />
                                and <TagAITagging style={{ marginRight: '0' }} />, then add them to your <b>Watchlist</b>.
                            </Text>
                        </animated.div>
                    </Col>
                    <Col xs={24} md={8}>
                        <animated.div style={trailEnrollement[2]}>
                            <div className='title-number'>3.</div>
                            <Title level={2} className='page-title'>
                                Let the AI Model Work for You
                            </Title>
                            <Text className='page-description'>
                                The <b>AI Model</b> analyzes all items in your <b>watchlist daily</b>,
                                sending you notifications on <b>the best Buy and Sell Momentum</b> aligned with your <b>Trading Profile</b>.
                            </Text>
                        </animated.div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Enrollement1;