import { Typography } from 'antd';
import React from 'react';
import MyBreadcrumb from '../../components/Breadcrumb';
import PageTitle1 from '../../components/PageTitle1';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import { FAQ_CRUMB } from '../FAQ';


const { Paragraph } = Typography;

const title = 'What do the tags like "Resilient" and "Win Rate High" mean?'

const TagMeaning = ({ onlyContent = false }) => {

    const content = <>
        <Paragraph className='blog-paragraph'>
            Tags such as <strong>"Resilient"</strong> and <strong>"Win Rate High"</strong> are AI-generated labels that provide key insights about the assets analyzed by Just Trade It:
        </Paragraph>

        <Paragraph className='blog-paragraph'>
            <strong>Resilient:</strong> Indicates assets with strong market fundamentals, including significant capitalization, low volatility, and proven resilience during bear markets. These are ideal for long-term or conservative investors.
        </Paragraph>

        <Paragraph className='blog-paragraph'>
            <strong>Win Rate High/Good/Avg/Low:</strong> These ratings reflect the efficiency of the AI model in analyzing a specific asset. A "Win Rate High" label signifies assets where the AI model has consistently demonstrated strong accuracy in identifying favorable trading opportunities by analyzing historical data, patterns, and market behavior. This tag is especially valuable for traders aiming to prioritize assets with a higher probability of achieving successful outcomes.
        </Paragraph>
    </>

    if (onlyContent)
        return content

    return (<>
        <MetaTags
            title={title}
            description='Learn about the meaning of tags like "Resilient" and "Win Rate High" on Just Trade It. These tags provide insights into asset stability and AI model efficiency to help make smarter trading decisions.'
            keywords='Resilient tag, Win Rate High tag, Just Trade It tags, asset stability, AI model efficiency'
        />

        <MyBreadcrumb items={FAQ_CRUMB.concat([
            {
                title: title,
            }
        ])} />

        <PageTitle1> • {title}</PageTitle1>

        {content}
    </>)
}

export default TagMeaning;